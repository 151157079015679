/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
// import "@google/model-viewer";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    currentCustomizationAtom,
    fabricAtom,
    loaderAtom,
    modelAtom,
    offsetValueAtom,
    preFixAtom,
    selectedImageScaletom,
    shareSelectedFabricAtom,
    uploadImageAtom,
} from "../../state";
import { GET_URL } from "../../constans";
import { ImageGlobalUrl } from "../../lib/constant";

const textures = {};

const PillowModelViewer = () => {
    const fabric = useRecoilValue(fabricAtom);
    const uploadImage = useRecoilValue(uploadImageAtom)
    const [modelViewer, setModelViewer] = useState(document.querySelector("#model-viewer"))
    const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
    const preFix = useRecoilValue(preFixAtom);
    const [loader, setLoader] = useRecoilState(loaderAtom)
    const currentCustomization = useRecoilValue(currentCustomizationAtom);
    const getCheckSupport = localStorage.getItem('checkSupport')
    const inputValue = useRecoilValue(selectedImageScaletom);
    const [offsetValue, setOffsetValue] = useRecoilState(offsetValueAtom)
    const [shareSelectedFabric, setShareSelectedFabric] = useRecoilState(shareSelectedFabricAtom);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setModelViewer(document.querySelector("#model-viewer"))
        }, 1000);
        // Cancel the interval after 5 seconds (5000 milliseconds)
        setTimeout(() => {
            clearInterval(intervalId);
        }, 1000);
        setLoader(true)
    }, []);

    useEffect(() => {

        // modelViewer?.addEventListener('load', function (evt, i) {
        if (modelViewer) {
            const { Front, Back } = uploadImage;
            updateMaterial();
            if (Front || Back) {
                if (Front) {
                    createTextureImages('Front', Front);
                }
                if (Back) {
                    createTextureImages('Back', Back);
                }
            }
        }
        // })
    }, [fabric?.partWiseFabric, modelAtomValue, uploadImage, modelViewer]);

    useEffect(() => {
        if (shareSelectedFabric?.length) {
            (async () => {
                setTimeout(async () => {
                    await getUVModel()
                }, 2500)
            })()
        }
    }, [shareSelectedFabric]);

    useEffect(() => {
        const { Front, Back } = uploadImage;
        if (Front || Back || fabric?.partWiseFabric.Front || fabric?.partWiseFabric.Back) {
            (async () => {
                // modelViewer?.addEventListener('load', function (evt, i) {
                if (modelViewer) {
                    setTimeout(async () => {
                        await getUVModel()
                        await getImageUVModel()
                    }, 1000)
                }
                // })
            })()
        }
    }, [fabric?.partWiseFabric, modelAtomValue, modelViewer, uploadImage]);

    const getUVModel = async () => {
        try {
            if (modelViewer?.model) {

                const model = modelViewer.model;
                let keys = [...Object.keys(fabric.partWiseFabric), ...Object.keys(uploadImage)];

                if (!model || !keys?.length) return;
                const parts = model[Object.getOwnPropertySymbols(model)[1]];
                let meterialIndexies = [];

                for (const key of keys) {
                    // eslint-disable-next-line no-loop-func
                    parts.forEach((part) => {
                        if (key === part.name) {
                            // const index = part.initialMaterialIdx
                            const index = part.initialMaterialIdx;
                            meterialIndexies = {
                                ...meterialIndexies,
                                [key]: index,
                            };
                        }
                    });
                }

                for (const fabricKey of keys) {
                    if (typeof meterialIndexies[fabricKey] === 'number' && isFinite(meterialIndexies[fabricKey])) {
                        const checkImage = Object.keys(uploadImage);
                        const material = modelViewer.model.materials[meterialIndexies[fabricKey]];
                        const diffuse_map = material?.pbrMetallicRoughness?.['baseColorTexture']?.texture?.sampler;
                        const normal_map = material?.normalTexture?.texture?.sampler;
                        const checkScale = inputValue?.[fabricKey]?.Horizontal !== 0 && inputValue?.[fabricKey]?.Vertical !== 0
                        const metallic_map = material?.pbrMetallicRoughness?.['metallicRoughnessTexture']?.texture?.sampler;

                        if (checkScale || !checkImage?.includes(fabricKey)) {
                            // set scale
                            const scale = {
                                u: Number(!checkImage?.includes(fabricKey) ? fabric.partWiseFabric[fabricKey].repeatX : inputValue?.[fabricKey]?.Horizontal),
                                v: Number(!checkImage?.includes(fabricKey) ? fabric.partWiseFabric[fabricKey].repeatY : inputValue?.[fabricKey]?.Vertical),
                            };
                            diffuse_map?.setScale(scale);
                            normal_map?.setScale(scale);
                            metallic_map?.setScale(scale);
                        }
                        if (checkImage?.includes(fabricKey) && offsetValue[fabricKey]) {
                            diffuse_map?.setOffset(offsetValue[fabricKey]);
                        }
                    }
                }
            }
        } catch (error) {
            console.log("🚀 ~ error file: PillowModelViewer.js:248 ~ getUVModel ~ error:", error)
        }
    };
    const getImageUVModel = async () => {
        try {
            if (modelViewer?.model) {

                const model = modelViewer.model;
                let keys = Object.keys(uploadImage);

                if (!model || !keys?.length) return;

                const parts = model[Object.getOwnPropertySymbols(model)[1]];
                let meterialIndexies = [];

                for (const key of keys) {
                    // eslint-disable-next-line no-loop-func
                    parts.forEach((part) => {
                        if (key === part.name) {
                            const index = part.initialMaterialIdx;
                            meterialIndexies = {
                                ...meterialIndexies,
                                [key]: index,
                            };
                        }
                    });
                }
                for (const fabricKey of keys) {
                    if (typeof meterialIndexies[fabricKey] === 'number' && isFinite(meterialIndexies[fabricKey])) {
                        const checkImage = Object.keys(uploadImage);
                        const material = modelViewer?.model?.materials?.[meterialIndexies?.[fabricKey]];
                        const diffuse_map = material?.pbrMetallicRoughness?.['baseColorTexture']?.texture?.sampler;
                        const normal_map = material?.normalTexture?.texture?.sampler;
                        const metallic_map = material?.pbrMetallicRoughness?.['metallicRoughnessTexture']?.texture?.sampler;
                        const checkScale = inputValue?.[fabricKey]?.Horizontal !== 0 && inputValue?.[fabricKey]?.Vertical !== 0

                        if (checkScale || !checkImage?.includes(fabricKey)) {
                            // set scale
                            const scale = {
                                u: Number(!checkImage?.includes(fabricKey) ? fabric.partWiseFabric[fabricKey].repeatX : inputValue?.[fabricKey]?.Horizontal),
                                v: Number(!checkImage?.includes(fabricKey) ? fabric.partWiseFabric[fabricKey].repeatY : inputValue?.[fabricKey]?.Vertical),
                            };
                            diffuse_map?.setScale(scale);
                            normal_map?.setScale(scale);
                            metallic_map?.setScale(scale);
                        }
                        if (checkImage?.includes(fabricKey) && offsetValue[fabricKey]) {
                            diffuse_map?.setOffset(offsetValue[fabricKey]);
                        }

                    }
                }
            }
        } catch (error) {
        }
    };

    const createAndApplyTextureImage = async (channel, path, keys) => {
        if (modelViewer?.model) {
            var texture = await modelViewer.createTexture(path);
            const model = modelViewer.model;
            const parts = model[Object.getOwnPropertySymbols(model)[1]];
            const meterialIndexies = [];
            const partWiseIndex = {};

            parts.forEach((part) => {
                const index = part.initialMaterialIdx
                if (index || index == 0) {
                    partWiseIndex[index] = part.name;
                    if (keys?.includes(part.name)) {
                        meterialIndexies.push(index);
                    }
                }
            });

            meterialIndexies.forEach((index) => {
                const material = modelViewer?.model?.materials?.[index];
                if (channel.includes("base") || channel.includes("metallic")) {
                    material?.pbrMetallicRoughness?.[channel]?.setTexture(null);
                    material?.pbrMetallicRoughness?.[channel]?.setTexture(texture);
                } else {
                    material[channel]?.setTexture(null);
                    material[channel].setTexture(texture);
                }
            });
        }
    }

    const createAndApplyTexture = async (channel, modelPartName, fabricImg) => {
        const base64Image = fabricImg?.search('data:image/png;base64') !== -1 ? true : false
        let imageConvert = fabricImg
       
        try {
            if (modelViewer?.model) {
                let texture = null;
                if (fabricImg) {
                    if (textures[fabricImg]) {
                        texture = textures[fabricImg];
                    } else {
                        texture = await modelViewer.createTexture(base64Image ? fabricImg : `${ImageGlobalUrl.replace('app', preFix)}/${imageConvert}`);
                        textures[fabricImg] = texture;
                    }
                }

                const model = modelViewer.model;
                const parts = model[Object.getOwnPropertySymbols(model)[1]];

                const meterialIndexies = [];
                const partWiseIndex = {};

                parts.forEach((part) => {
                    const index = part.initialMaterialIdx;
                    partWiseIndex[index] = index;
                    if (modelPartName === part.name) {
                        meterialIndexies.push(index);
                    }

                });

                meterialIndexies.forEach((index) => {
                    const material = modelViewer.model.materials[index];

                    if (fabricImg) {
                        if (channel.includes("base") || channel.includes("metallic")) {
                            material.pbrMetallicRoughness[channel].setTexture(null);
                            material.pbrMetallicRoughness[channel].setTexture(texture);
                        } else {
                            material[channel]?.setTexture(null);
                            material[channel].setTexture(texture);
                        }
                    } else {
                        material[channel]?.setTexture(null);
                    }
                    if (['Front_Inset_Band', 'Back_Inset_Band', 'Back_CenterBand', 'Front_CenterBand', 'Back_Vertical_Band', 'Back_Double_Band', 'Front_Vertical_Band', 'Front_Double_Band', 'Fringe']?.includes(modelPartName)) {
                        material.setAlphaMode('MASK')
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    };
    const updateMaterial = async () => {
        let keys, material;

        if (!fabric.partWiseFabric) {
            return;
        }

        keys = Object.keys(fabric.partWiseFabric)?.length ? Object.keys(fabric.partWiseFabric) : [shareSelectedFabric?.selectedKey];
        if (shareSelectedFabric?.selectedKey?.length > 0) {
            await createAndApplyTexture(
                "baseColorTexture",
                shareSelectedFabric?.selectedKey,
                shareSelectedFabric?.img
            );
            await createAndApplyTexture(
                "metallicRoughnessTexture",
                shareSelectedFabric?.selectedKey,
            );
            await createAndApplyTexture(
                "normalTexture",
                shareSelectedFabric?.selectedKey,
            );
        }

        material = fabric;

        if (keys.length === 0) {
            return;
        }
        for (const key of keys) {
            if (material.partWiseFabric[key]) {
                await createAndApplyTexture(
                    "baseColorTexture",
                    key,
                    key === 'Fringe' ? material.partWiseFabric[key].alphaDiffuse : material.partWiseFabric[key].diffuse
                );
                await createAndApplyTexture(
                    "metallicRoughnessTexture",
                    key,
                    material.partWiseFabric[key].metallicRoughness
                );
                await createAndApplyTexture(
                    "normalTexture",
                    key,
                    material.partWiseFabric[key].normal
                );

                const bandPart = ['Front_Inset_Band', 'Front_Vertical_Band', 'Front__CenterBand', 'Front_Double_Band']
                if (currentCustomization?.band?.name !== 'No' && currentCustomization?.band?.type === 'single' && bandPart.includes(key)) {
                    const nameToNameMap = {
                        "Inset Band": "Back_Inset_Band",
                        "Center": currentCustomization?.size?.name === '20x20' ? "Back_CenterBand" : "Back_Vertical_Band",
                        "Vertical": "Back_Double_Band",
                    }
                    await createAndApplyBlankTexture(
                        "baseColorTexture",
                        nameToNameMap[currentCustomization?.band?.name],
                        'Blank Image.png'
                    );
                }
            }
        }
    };

    const createAndApplyBlankTexture = async (channel, modelPartName, fabricImg) => {
        try {
            if (modelViewer?.model) {
                let texture = null;
                if (textures[fabricImg]) {
                    texture = textures[fabricImg];
                } else {
                    texture = await modelViewer.createTexture(`${window.location.origin}/${fabricImg}`);
                    textures[fabricImg] = texture;
                }

                const model = modelViewer.model;
                const parts = model[Object.getOwnPropertySymbols(model)[1]];

                const meterialIndexies = [];
                const partWiseIndex = {};

                parts.forEach((part) => {
                    const index = part.initialMaterialIdx
                    partWiseIndex[index] = part.name;
                    if (modelPartName === part.name || part.name === "Wood") {
                        meterialIndexies.push(index);
                    }
                });

                meterialIndexies.forEach((index) => {
                    const material = modelViewer.model.materials[index];
                    if (channel.includes("base") || channel.includes("metallic")) {
                        material.pbrMetallicRoughness[channel].setTexture(null);
                        material.pbrMetallicRoughness[channel].setTexture(texture);
                    } else {
                        material[channel]?.setTexture(null);
                        material[channel].setTexture(texture);
                    }
                    if (['Front_Inset_Band', 'Back_Inset_Band', 'Back_CenterBand', 'Front_CenterBand', 'Front__CenterBand', 'Back_Vertical_Band', 'Back_Double_Band', 'Front_Vertical_Band', 'Front_Double_Band', 'Fringe']?.includes(modelPartName)) {
                        material.setAlphaMode('MASK')
                    }
                });
            }

        } catch (err) {
            console.error(err);
        }
    };

    const createTextureImages = (imageType, image) => {
        createAndApplyTextureImage('baseColorTexture', image, imageType);
        createAndApplyTextureImage('metallicRoughnessTexture', 'metalicRoughness.jpeg', imageType);
        createAndApplyTextureImage('normalTexture', 'normal.jpg', imageType);
    }

    document.querySelector("#model-viewer")?.addEventListener("progress", (event) => {
        if (event.detail.totalProgress === 1) {
            setModelAtomValue({
                ...modelAtomValue,
                loading: 100,
            })
            setLoader(false)
        }
    })
    if (modelAtomValue.src.replace('app', preFix) && preFix) {
        return (
            <>
                <div style={{ position: 'relative' }}>
                    <div id="card" style={{
                        opacity: '1',
                        transition: 'opacity 1s ease-in-out',
                        height: "57vh", width: "100%"
                    }}>
                        <model-viewer
                            tone-mapping="neutral"
                            src={modelAtomValue.src.replace('app', preFix)}
                            camera-controls
                            disable-pan
                            // camera-orbit="0deg 90deg 3m"
                            style={{ height: "57vh", width: "100%" }}
                            id="model-viewer"
                            exposure="0.8"
                        ></model-viewer>
                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }
}

export default PillowModelViewer