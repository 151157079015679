const content = {
  category: {
    VINTAGE_FURNITURE: {
      name: "Vintage Furniture",
      image: "vintage_furniture.png",
    },
    PILLOW: {
      name: "Pillow",
      image: "pillow.png",
    },
    DRAPERY: {
      name: "Drapery",
      image: "drapery.png",
    },
  },
  inspirations: {
    VINTAGE: {
      Vintage_Dresser: {
        name: "Vintage Dresser",
        image: "Vintage_Dresser.jpg",
      },
      Tall_Vintage_Dresser: {
        name: "Tall Vintage Dresser",
        image: "Tall_Vintage_Dresser.jpg",
      },
      // Vintage_Detailed_Dresser: {
      //   name: "Vintage Detailed Dresser",
      //   image: "Vintage_Detailed_Dresser.png",
      // },
      Vintage_Nightstand: {
        name: "Vintage Nightstand",
        image: "Vintage_Nightstand.jpg",
      },
      Long_Vintage_Credenza: {
        name: "Long Vintage Credenza",
        image: "Long_Vintage_Credenza.jpg",
      },
      Vintage_Buffet: {
        name: "Vintage Buffet",
        image: "Vintage_Buffet.jpg",
      },
    },
    PILLOW: {
      // 0: {
      //   name: "Double Banded Lumbar Pillow",
      //   image: "pillow1.png",
      //   modelName:
      //     "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/83a3c523-2468-45c8-a0c5-a7cb45c623cf/d9ce60c4-cfee-474a-8593-6f3dc33b43eb.gltf",
      //   settings: {
      //     fabricFront: {
      //       diffuse:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/c070a0a0-1b55-4fbc-a809-bebac9c50c76.jpeg",
      //       metallicRoughness: "",
      //       normal: "",
      //     },
      //     fabricBack: {
      //       diffuse:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/c070a0a0-1b55-4fbc-a809-bebac9c50c76.jpeg",
      //       metallicRoughness: "",
      //       normal: "",
      //     },
      //     size: "12x20",
      //     border: "",
      //     band: "Vertical",
      //   },
      // },
      // 1: {
      //   name: "2 Sided Fringed Pillow ",
      //   image: "pillow-3.png",
      //   modelName:
      //     "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/1ea48751-855a-401f-b96d-d65c4897b7b4/06160bf2-2a3a-4b9b-aece-5afd6ce3298e.gltf",
      //   settings: {
      //     fabricFront: {
      //       diffuse:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/c070a0a0-1b55-4fbc-a809-bebac9c50c76.jpeg",
      //       metallicRoughness:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/thumbnail/99269d4a-d123-4fce-88e7-d8b7b39bd943.jpeg",
      //       normal:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/9f5dc173-e748-47c6-a5be-3e1986c64676.jpeg",
      //     },
      //     fabricBack: {
      //       diffuse:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/c070a0a0-1b55-4fbc-a809-bebac9c50c76.jpeg",
      //       metallicRoughness:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/thumbnail/99269d4a-d123-4fce-88e7-d8b7b39bd943.jpeg",
      //       normal:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/9f5dc173-e748-47c6-a5be-3e1986c64676.jpeg",
      //     },
      //     size: "20x20",
      //     border: "",
      //     band: "Vertical",
      //   },
      // },
      // 2: {
      //   name: "Lumbar Pillow with Piping",
      //   image: "pillow4.png",
      //   modelName:
      //     "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/163ba979-fb19-481f-884b-c3783550386b/072b5a4c-ce12-4926-bd92-c0b46d3d373a.gltf",
      //   settings: {
      //     fabricFront: {
      //       diffuse:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/c070a0a0-1b55-4fbc-a809-bebac9c50c76.jpeg",
      //       metallicRoughness:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/thumbnail/99269d4a-d123-4fce-88e7-d8b7b39bd943.jpeg",
      //       normal:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/9f5dc173-e748-47c6-a5be-3e1986c64676.jpeg",
      //     },
      //     fabricBack: {
      //       diffuse:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/c070a0a0-1b55-4fbc-a809-bebac9c50c76.jpeg",
      //       metallicRoughness:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/thumbnail/99269d4a-d123-4fce-88e7-d8b7b39bd943.jpeg",
      //       normal:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/9f5dc173-e748-47c6-a5be-3e1986c64676.jpeg",
      //     },
      //     size: "20x20",
      //     border: "",
      //     band: "Vertical",
      //   },
      // },
      // 3: {
      //   name: "Pillow with Inset Band",
      //   image: "pillow5.png",
      //   modelName:
      //     "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3d0c8630-70ea-4f68-9bf8-09ce4d50d5be/ca93978a-39f2-405b-a407-2d2972bbbeb2.gltf",
      //   settings: {
      //     fabricFront: {
      //       diffuse:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/c070a0a0-1b55-4fbc-a809-bebac9c50c76.jpeg",
      //       metallicRoughness:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/thumbnail/99269d4a-d123-4fce-88e7-d8b7b39bd943.jpeg",
      //       normal:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/9f5dc173-e748-47c6-a5be-3e1986c64676.jpeg",
      //     },
      //     fabricBack: {
      //       diffuse:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/c070a0a0-1b55-4fbc-a809-bebac9c50c76.jpeg",
      //       metallicRoughness:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/thumbnail/99269d4a-d123-4fce-88e7-d8b7b39bd943.jpeg",
      //       normal:
      //         "5f991484a0d40e0e988e1722/finish/636dd66c71945d00088f89ea/9f5dc173-e748-47c6-a5be-3e1986c64676.jpeg",
      //     },
      //     size: "20x20",
      //     border: "",
      //     band: "Vertical",
      //   },
      // },
      0: {
        name: "Pillow Lumbar",
        image: "lumber-pil.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/90fa5f91-0a1c-4eb4-b468-36eddf6ed38d/6dbc301f-1310-4cf0-8d0e-72857b194a41.gltf",
        settings: {
          size: "12x20",
          border: "",
          band: "",
        },
      },
      1: {
        name: "Pillow Square",
        image: "square-pil.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/81305c7e-0b31-4d42-affd-e5f2a7abec42/d9c2a60e-2385-4018-b9d1-f7fe2e9d0db6.gltf",
        settings: {
          size: "18x18",
          border: "",
          band: "",
        },
      }
      
    },
    DRAPERY: {
      Drapery: {
        name: "Drapery",
        image: "drapery_inspiration1.png",
        size: {
          '36" x 48"': {
            selectedImg: "Drapery/Small window_select.png",
            unselectedImg: "Drapery/Small window_deselect.png",
          },
          '72" x 90"': {
            selectedImg: "Drapery/Large Window_select.png",
            unselectedImg: "Drapery/Large Window_deselect.png",
          },
        },
        modelName:
          "https://app.poly9.ai/public/5feac293447a060008714d9f/3dProduct/styled-GLTF/938f31db-c24a-40e8-a657-882b34dff90a/dd1f9d97-b478-4c15-9636-95517bf54614.gltf",
      },
      Shades: {
        name: "Shades",
        image: "drapery_inspiration2.png",
        // modelName:"2"
        modelName:
          "https://app.poly9.ai/public/5feac293447a060008714d9f/3dProduct/styled-GLTF/938f31db-c24a-40e8-a657-882b34dff90a/dd1f9d97-b478-4c15-9636-95517bf54614.gltf",
      },
      Valance: {
        name: "Valance",
        image: "drapery_inspiration3.png",
        // modelName:"3"
        modelName:
          "https://app.poly9.ai/public/5feac293447a060008714d9f/3dProduct/styled-GLTF/938f31db-c24a-40e8-a657-882b34dff90a/dd1f9d97-b478-4c15-9636-95517bf54614.gltf",
      },
      Cornice: {
        name: "Cornice",
        image: "drapery_inspiration4.png",
        // modelName:"4"
        modelName:
          "https://app.poly9.ai/public/5feac293447a060008714d9f/3dProduct/styled-GLTF/938f31db-c24a-40e8-a657-882b34dff90a/dd1f9d97-b478-4c15-9636-95517bf54614.gltf",
      },
    },
    UPHOLSTERY: {
      0: {
        name: "Kent Sofa",
        image: "upholstery/1.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0f4151bd-1123-4ebe-98a6-876c094427c7/9eabbc5e-5765-4287-9993-22ba794341b4.gltf",
      },
      1: {
        name: "Milford Sofa",
        image: "upholstery/2.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/74dae2aa-4927-4850-88af-7000f9cd3e45/d3776256-1113-44c7-b1ff-27c86273914c.gltf",
      },
      2: {
        name: "Sally Chair",
        image: "upholstery/3.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/07b4a55d-e899-4a0a-b4ca-9a599ac990f6/377a63ad-6b07-4aa0-a9f7-9b43425f8f94.gltf",
      },
      3: {
        name: "Leo Ottoman",
        image: "upholstery/4.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7d3d3361-1acd-4c05-bfb4-f6dac15bf034/5fa4ba02-7403-4aac-896e-4890f4952ea6.gltf",
      },
    },
  },
  size: {
    "26X26": {
      name: "26 x 26",
      image: "26x26.png",
    },
    "18X18": {
      name: "18 x 18",
      image: "18x18.png",
    },
    "14X20": {
      name: "14 x 20",
      image: "14x20.png",
    },
  },
  fabric: {
    front_side: {
      name: "Front Side",
      image: "front_side.png",
    },
    back_side: {
      name: "Back Side",
      image: "back_side.png",
    },
  },
  border: {
    flange1: {
      name: 'Flange 1"',
      image: "flange1.png",
    },
    flange2: {
      name: 'Flange 2"',
      image: "flange2.png",
    },
    welt: {
      name: "Welt",
      image: "welt.png",
    },
  },
  trim: {
    center: {
      name: "Center",
      image: "center.png",
    },
    vertical: {
      name: "Vertical",
      image: "vertical.png",
    },
  },
  Handle: {
    Black_Walnut_Wood_And_Gold_Ends_TKnob: {
      name: "Black Walnut Wood And Gold Ends TKnob",
      sortName: 'Wood T-Knob',
      image: "handle_thumb/Black_Walnut_Wood_And_Gold_Ends_TKnob.jpg",
      code: "31739265056827"
    },
    Large_Brushed_Brass_Greek_Key_Pull: {
      name: "Large Brushed Brass Greek Key Pull",
      sortName: 'Large Greek Pull',
      image: "handle_thumb/Large_Brushed_Brass_Greek_Key_Pull.jpg",
      code: "31736945508411"
    },
    Brass_Open_Circle_Knob: {
      name: "Brass Open Circle Knob",
      sortName: 'Circle Knob',
      image: "handle_thumb/Brass_Open_Circle_Knob.jpg",
      code: "31736107892795"
    },
    Small_Gold_Deco_Drawer_Pull: {
      name: "Small Gold Deco Drawer Pull",
      sortName: 'Small Deco Pull ',
      image: "handle_thumb/Small_Gold_Deco_Drawer_Pull.jpg",
      code: "31736177000507"
    },
    Elegant_Polished_Nickel_Pull: {
      name: "Elegant Polished Nickel Pull",
      sortName: 'Nickle Pull',
      image: "handle_thumb/Elegant_Polished_Nickel_Pull.jpg",
      code: "20536927125563"
    },
    T_Pull_Knob: {
      name: "T Pull Knob",
      sortName: 'T-Knob',
      image: "handle_thumb/T_Pull_Knob.jpg",
      code: "20537172557883"
    },
    'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
      name: "Large Black Walnut Wood & Gold Drawer Handle",
      sortName: 'Dark Wood Pull ',
      image: "handle_thumb/Large_Black_Walnut_Wood_Gold_Drawer_Handle.jpg",
      code: "31738947895355"
    },
    'Large_Acrylic_&_Gold_Handle_Pull': {
      name: "Large Acrylic & Gold Handle Pull",
      sortName: 'Arcylic Pull',
      image: "handle_thumb/Large_Acrylic_&_Gold_Handle_Pull.jpg",
      code: "31754090643515"
    },
    Handles_Cabinet_Hardware: {
      name: "Handles Cabinet Hardware",
      sortName: 'Leather Pull',
      image: "handle_thumb/Handles_Cabinet_Hardware.jpg",
      code: "31726185021499"
    },
    Furniture_Door_Knockers: {
      name: "Furniture Door Knockers",
      sortName: 'Square Pull',
      image: "handle_thumb/Furniture_Door_Knockers.jpg",
      code: "31736008245307"
    },
    Cabinet_Door_Knob_Hardware: {
      name: "Cabinet Door Knob Hardware",
      sortName: 'Quartrefoil Knob',
      image: "handle_thumb/Cabinet_Door_Knob_Hardware.jpg",
      code: "31737235800123"
    },
    Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
      name: "Large Light Beech Wood and Gold Drawer Handle",
      sortName: 'Wood Pull',
      image: "handle_thumb/Large_Light_Beech_Wood_and_Gold_Drawer_Handle.jpg",
      code: "31736409030715"
    },
    Large_Gold_Deco_Drawer_Pull: {
      name: "Large Gold Deco Drawer Pull",
      sortName: 'Quartrefoil Knob',
      image: "handle_thumb/Large_Gold_Deco_Drawer_Pull.jpg",
      code: "31736169660475"
    },
    Small_Brushed_Brass_Greek_Key_Pull: {
      name: "Small Brushed Brass Greek Key Pull",
      sortName: 'Large Greek Pull',
      image: "handle_thumb/Small_Brushed_Brass_Greek_Key_Pull.jpg",
      code: "31726085046331"
    },
    Domed_Cut_Glass_Knob: {
      name: "Domed Cut Glass Knob",
      sortName: 'Glass Knob',
      image: "handle_thumb/Domed_Cut_Glass_Knob.jpg",
      code: "20537089228859"
    },
    Small_White_Beech_Wood_With_Gold_Ends_Handle: {
      name: "Small White Beech Wood With Gold Ends Handle",
      sortName: 'Wood & Gold Pull',
      image: "handle_thumb/Small_White_Beech_Wood_With_Gold_Ends_Handle.jpg",
      code: "31736707481659"
    },
    Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
      name: "Small Black Walnut Wood with Gold Ends Handle",
      sortName: 'Dark Wood  & Gold yPull',
      image: "handle_thumb/Small_Black_Walnut_Wood_with_Gold_Ends_Handle.jpg",
      code: "31738223067195"
    },
  },
  THREE_D_MODELS: {
    VINTAGE_FURNITURE: {
      Vintage_Dresser: {
        id: "13290jsdfd923123",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/67cadcdf-cf3f-4817-8ca2-ab2965c0dac2/ff73f089-b79e-4d39-be60-3644f7f2dfb1.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/cb8556c4-06f7-4b7f-ab9c-01d790b58168/48ec4f74-54f9-4745-8bce-1161df9a7c01.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f87fc6b9-6868-478a-8465-7010e7d29acd/6d8056b5-62cd-4498-84e1-3880bd39bafb.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8b1a8a5a-6ce6-42ff-bd12-f5a4410fa072/2dcab614-a3c8-4bdd-becc-9ac8cb106074.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/42460bb5-c7e0-4ae7-9d90-5d119144097b/1f1b4520-0d29-47e6-8099-91bf1cfdf3ec.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/44d6e4e9-b8af-4253-a1d4-29c3098648b8/247fcd18-4331-4ef1-b62f-f80b199309bf.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/84e3fde2-f552-46f2-b34c-d8c94c703a3b/610cda8c-19c7-4c63-9937-87027f78b83d.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/12bb6bdf-1955-49da-be68-dea19f31bc8e/1234ec12-8f1e-4903-b0e7-7cd551967585.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/23ed0356-93ce-49b7-8416-5a883829df50/81ce706b-ca22-4c9b-927c-bc66dd1abce3.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f41fd9cd-af6a-4ff1-8db5-2ef44c941a6e/18f4d6f8-9034-4483-82c9-6ba7ff9183aa.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/332920aa-27f9-4f98-bb80-a7bc4c79f1f5/a82b827a-8bc2-499e-9762-6e7f3aac8ccb.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ee7c3f16-3f09-499f-87cd-c3d4f28c8b82/83f1613d-bef7-4f9f-b529-4aaecbb844fb.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/16f03cc4-10fa-46cc-9cac-3628fddc9c4d/0c6682bb-cbf1-4aca-b6f8-86edf3a4cee8.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d615ad87-fdda-4bee-82ff-8cd689115471/7ad8b148-ff82-4b0b-a7dd-0dda721dc7a4.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/39029a5e-76c2-4626-9e1d-1dde43cabaaa/cf2e90af-36e8-43bd-b93e-65e64025db9a.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7c6613ba-c852-44a2-b18d-6e40a61073b1/a98e15fd-4cbd-4c76-8faf-5cfcbc4532a4.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/07ef5777-3490-4580-972f-77509b542317/156e7e5a-3aaf-450c-bf73-c93ec68794aa.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/89a19747-2469-4ee2-b6a1-15628202cb9f/f2396de3-8747-47a4-a54e-4b549dbb94b1.gltf"
        },
      },
      Tall_Vintage_Dresser: {
        id: "13290jsdfd923123",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/82c3d2f4-e669-4d51-8009-2e5d364ae145/9492b831-fe0a-4e41-a58a-861e795312d1.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/47c8d10e-599d-48be-a715-0ebf35517f0d/9c4acb24-08fb-43df-a40f-200034509c31.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/02baae4c-c32e-4437-a28b-a748f18b5480/c2d783c5-322a-4909-9213-09887fcf714d.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/cc8304fc-7f7a-4d95-b22a-9cf6b9f982fe/3d91bd5f-584a-4720-b69c-6396e61d5e43.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3506f0eb-91f6-4363-a5d7-8ba97a9e01f1/cfe90273-ac48-49e7-a8e4-d62c7f2da8dd.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e5db9a47-1c53-494e-9296-3cce7e16361d/02ef6fca-37fe-4e3d-8443-d76502427d38.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f9c509a0-bce6-4699-97e3-ecb8ac7d80eb/55123db2-18a5-4720-bd6c-39ff3caa8101.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f0c0920c-918a-483c-96fc-edc19f9335c4/74f64c79-1cc0-4044-a46e-94aadd072efe.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/776bf88b-275d-4ff1-b608-bd6056d5d602/ff8bc96c-41fc-4ae8-8afd-1d7129ddf9d1.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/44b05068-f163-4749-af8a-d47b87c8753a/f73312c7-fa0a-4b15-b419-040e8b384f10.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/fc50f59b-c1bd-479f-b900-7db9eab382f1/190fbee8-3554-4233-8548-66887d71d606.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/82d52fd5-93c5-471b-8a79-88686bcf3e73/83cc936d-ca1e-41db-9728-e0acb166ca28.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b562cdfc-4b2a-490c-83b3-b582032429a0/b7e04269-da05-4de7-8a1b-b0ae7ed68baa.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8dbb6c11-bd47-4089-8663-d77a3f66d2e1/9dc9d068-dc1d-411e-99f1-5aaae32a1947.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/60eb496c-2d82-4d51-a832-3fb71213d3a8/a3dd4763-5d0f-4bbf-8adb-4fac9f535959.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3ec9598c-f10b-40a2-bcd7-fdc83160a56d/e799ee41-6c73-41ee-b5d5-3bafae6a842a.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c1ff4f8b-4ee7-43a0-8f8e-0cc42b034d97/bb66977b-e937-4632-a82a-cd0def09b41b.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/41a5fb63-dc1a-4d6b-a76e-548936d6eb70/63baae70-fab7-4e48-bda7-7b972583d56f.gltf"
        },
      },
      Vintage_Buffet: {
        id: "13290jsdfd923123",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/84c42504-c78c-47d2-b351-d00e6e9057d4/6b9bc8fa-c952-42e8-a003-602146a7fc08.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2e6ce5ff-ca05-4fad-9773-47078f105ae3/2bc23197-6249-438b-b25a-caa0271e0ad4.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/04545fbe-afa2-49d2-9fae-4fe08625f2a0/8574a83e-75b1-4bdf-8b01-0f40373c7a50.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3eaa5ba4-4875-4ff5-b77b-df9c93d96784/fb215cef-d1f3-4310-9be9-4530d3b31f7c.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a75ffc7d-f88e-4f74-a267-f4025df1b975/62041172-3706-4f8c-9582-8a75a01c85dc.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e275aa00-244d-4af8-ae26-e99008699693/92df7dc6-2180-4d81-8571-c698a04d2378.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2232143b-3303-4246-94b2-24f60118760a/587b6dd3-f1c1-42ad-b109-62182dece7bf.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6cb02229-b60e-4411-adca-a7704e29d515/8cf5b936-c2d9-4e97-a636-5acfc5fb3de9.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/cb80671f-441f-461f-a9ba-3a27b089356c/b114a93c-8b98-458e-b9ee-0d28b39627da.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bbeabc02-68cc-4220-a77b-bb5895efc1a8/4d67210b-de6b-47e8-be5b-c70c02006954.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a37a9d3e-8b0a-417a-96dc-5db56eee0ded/c19c73ff-f423-41b2-bd7c-d6d62c76ca40.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2d729c79-6c17-48e0-aeed-6b7d30d0f525/27d5337e-9cf7-4788-b470-21f55d60d94e.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c4a4ba85-a7ec-43e3-ace5-3747b9f5f125/9170e74d-367f-4cd1-8ac8-e638aa649dc5.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d3ab0c56-a045-4193-8296-aa16d5fbc3a1/1c597702-3de0-463a-9911-555185eeae35.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/52e58516-6c41-482d-b331-60bb25521a37/b8922c5c-ed66-4d34-8b48-1b5dfcba7f18.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bd64195b-d759-4d5e-857a-6cd644adf292/b3f2a2be-c43c-40b0-a6d9-533ecd24c299.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0a22849c-47ba-424b-beca-7fd09f78b293/ab0b7fe4-a527-4598-8c70-fce9385ee6e4.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/599601c4-59ad-4d7e-aec1-927da539156e/417f6bd3-25f7-4cf9-9dad-fa128dee8be8.gltf"
        },
      },
      Long_Vintage_Credenza: {
        id: "Default",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6a47296e-9395-42c3-bf18-bb34be4b599d/0bf2fc66-66d4-4db7-8c36-9dc1c94bc75b.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a04952e3-6a83-4939-a8e9-75922eee45a6/574230b0-253f-4639-b3d8-56f8a97d98a7.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/09edde78-e55f-4021-885e-da8e492e2abe/df7be639-3e76-4a70-9ef6-88b093dd8c6d.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3033e44a-696b-4d77-99a7-2b13560aaeca/b2877f47-eb39-47e1-bf10-b74d58138786.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bc87ff26-7d2a-4731-a4c1-516fe339afb4/6185226f-14a0-4da8-8b61-d9c3c542067d.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7c615316-8fb7-40e1-bc50-6ee8b51864bd/c57cd7ea-f76a-4d57-8509-c35a552db2d0.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/32fd5f45-f5ad-4858-ad4d-eee9c1fcb0f9/3a08e095-cb90-480a-a692-22be8fcf6158.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8a01de8c-4320-4061-82c5-1b9f31b35741/78d54cd8-2b9c-4004-a474-9245a00ca872.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0a17bee3-511a-495b-8581-d727ccfb2647/4e6aac44-8bdc-4d16-bd23-34f0dd2a68fb.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/83332827-7215-46d5-83ea-53e227578df4/586c21c8-1f8a-4a12-a0c1-dc9db44ce676.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d681a760-195e-4e95-a444-d12268b3d30e/22761dc9-ee84-4433-a7d9-74de22229e15.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ca4bac61-9253-4a3f-87b8-2f96d43c52c6/e12f8684-8ed0-4765-9ada-871eee9ad4b7.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/64ad01cb-97cc-44af-a202-f7d411b3d3c0/5f57537a-714d-4bad-a5b8-aeb1747fba07.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/69ce7ded-997d-440a-8e29-d05f82faed80/a6a24788-1c42-4d98-95de-b329cb7b5837.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6552e56f-2d29-48e6-a8f6-613192c0864f/81f95aeb-e7c0-41c5-b73e-323022aebdeb.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c6725b7e-e36c-4ef3-ab0b-13c736d17264/23feac3e-7eca-44a7-9a43-4935c1b4b08c.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/870fec42-d0b4-446f-85b5-856f22d0e57f/21ea9f89-51e2-48ed-a91e-25532a914368.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/162f66fa-daf3-45f3-bcec-205e3c7741f9/dde08cc6-d41f-4d8a-8b88-0957b9826d1f.gltf"
        },
      },
      Vintage_Nightstand: {
        id: "Default",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/de91c2a2-a0d6-4288-8b59-3e1ebb84ee45/f6570c95-744f-4763-b66f-4be0bea5ab03.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7ae6b805-747a-4d60-b813-63a990c015cb/4122b933-9640-406e-a8b9-11ac366d18d9.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/57c5fa7f-0d14-473d-b484-896d90b7d4f9/8b2bb45c-a18d-4113-bcba-41d52c173bc4.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/40df853d-be3c-484d-b642-87dbd991dd15/04e2c0fc-3ea6-44f2-8109-51a308f048a6.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2f0eee31-69c1-4af9-97e2-7d5f15d8a891/6d643d41-e49e-48c3-a865-64034048f633.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/52f158ac-ca5a-45d8-95b0-80d96c8e3132/ec22b522-ec34-4a03-a2f0-e8ad591388b5.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/5d1dfc2b-de26-4d2f-bbd4-a1979c28dba9/71a9cc20-e2dd-4111-ae89-1d1027f6808c.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/84f08cdb-5917-4e48-8e44-179ee0c17e6a/dabae027-4468-48b5-91a3-fcf2f0f03227.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/386ee966-7bde-4d12-972a-50fd488beb80/2b7e5121-02b0-4b09-a74b-90c805ce1a72.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3c39714d-9b62-4e8d-aacc-dfa1c3d6ff3d/3c3db2f8-ec5f-4ec2-8af2-bb949d26fd5a.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d456e1c7-851d-443c-b3a0-985d88136a6e/919fb8fc-e54b-4e95-bf59-a55f65fa8c24.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ceaacf55-aa74-4efe-ad7a-8161a4efd77c/848fd75e-653f-40de-afc8-81eea26e8375.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/dea65350-bb0b-4fce-9d64-b805a71f54ae/54c7c411-ff3b-4193-b358-f5cedb0279ff.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b0a7dd46-bd07-412b-b398-3d4d47422f2e/50c22627-a2ea-42b3-9d1c-8a0d27d8ac36.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/46d8e0d3-214c-433e-bb2e-9e889d25ecdb/264cfeca-5b98-46c4-9a71-648fa72abec5.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c1900e13-05f1-4307-a16a-74830fccf250/a45d3e6d-817b-465a-b2f2-c55b637cc1ca.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/9a73c53d-deb0-4245-91ca-3c52694dfd10/5043a827-3180-43e8-8174-415ec0f620c9.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/47469c9b-9f8e-4a57-8a28-6cf020b19290/25041ba1-c8f4-4bc3-9068-f634cf6b8f46.gltf"
        },
      },
      Vintage_Detailed_Dresser: {
        id: "Default",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/82c6f554-034b-41aa-9350-e1c3a7811b94/0a425b3a-9198-46c3-8883-45a6059d3f24.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/45d29224-803f-4939-a5f0-5de8bf62f7af/2f315e01-0b70-4071-af3d-fefd32b4e82f.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/63e01844-0873-4847-ab6f-4d21c3a418b0/c8238820-ad43-4d19-bbbd-3c42a51ce88d.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c178c396-f21f-4de5-9907-d8c9dc8b110c/c47185a2-148f-49d4-ac60-9e3cac455f36.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ffced5b7-20f8-4bd4-af24-b91462838ec7/1fc06ab5-2f2a-4524-82ad-0a553c4f98d5.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ee2bafdb-289b-41d9-8101-057f54f8575c/fd250356-cfab-4489-b863-1f277506f2c7.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/9dcc5b36-ab41-47da-8deb-d8b03057477d/c76b1c49-8a01-4688-9630-745be8773dcc.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/35c00ce0-8c8f-4ae5-ae9c-2b16e4031d85/3b67d10b-389c-4309-9533-d545d9086056.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c3cefb2a-5133-4e9d-87b5-ca323303f223/bdc89d77-78aa-4a95-8d24-533b10b8713d.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ac5292a2-bc20-4735-b760-acb7f26daa7f/593e9c9f-926f-44f1-a281-4dad33dd970f.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0e59e32e-c9ce-41d4-a341-dc28a6300cc8/d2c41a05-0229-484b-bbe5-449ac5d1f756.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/26d068da-e3b1-4ef2-a948-5efd1d2a2453/ba1ce443-7a1e-485e-93ce-676f38273a8d.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ed250cbd-bc06-4677-9221-9c27f7a07bd6/2111fbbf-24d2-404d-9bad-eeb3ba82300f.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d7c62ef8-64c1-488b-977b-6d0f844037de/7bc6c961-5893-4e04-b191-e883c350636b.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4866e6a9-0035-4926-923b-cf53ee03fa70/11f205a3-1966-439b-8d2f-0dcaf55943fd.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c78d45e0-22fc-4b26-b71a-8bc479625579/10f5d881-c37c-47d8-8c49-fc8bb78fdf90.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7383f291-1bdb-47ee-91b2-9fb7f49644f2/32dbc369-a071-477e-aa2b-bbe6412a6448.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/deddb2ae-e5af-403b-a9b1-1c532b7cbdd6/e8386cd0-8cb7-422c-8b7c-6aa4c30cf79f.gltf"
        },
      },
    },
    PILLOW: {
      size: {
        "26x26": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "24x24": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "22x22": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "20x20": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "18x18": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
          welt: {
            id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6f2cc826-a3e0-4a05-98e8-0de944f42c22/72ee6f84-56b9-4136-b60f-4616487d06ab.gltf",
          },
        },
        "16x16": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "14x22": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7d98c5f3-059e-4fd0-acaa-64bec4114b36/b60f70fb-419e-4c5d-8116-5881ddd86dcb.gltf",
          weld: {
            id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/5490ab9c-22c0-403b-984b-ccd046dcfcdb/c39d66bf-d440-4e8c-8b54-61e3edd4d502.gltf",
          },
        },
        "12x20": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7d98c5f3-059e-4fd0-acaa-64bec4114b36/b60f70fb-419e-4c5d-8116-5881ddd86dcb.gltf",
        },
      },
    },
  },
};

export const PillowModelData = {
  size: {
    "20x20": {
      id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/81305c7e-0b31-4d42-affd-e5f2a7abec42/d9c2a60e-2385-4018-b9d1-f7fe2e9d0db6.gltf",
      border: {
        'Flange 1"': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c84a40e1-10bd-4fdc-9766-8787befddfde/d5c7a281-e53b-42de-9db8-b4f4af8a2d6b.gltf",
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e630911a-0028-4acb-9045-30cb1c129fdd/bfe35f64-7eb9-42b1-8e57-c2e9b06d6518.gltf",
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/670e07c6-cfbc-45b4-b62a-a2ea75eaff01/1c1d0996-fc4f-409c-979d-a04eb62ef863.gltf",
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/eb6a0d90-0a76-4502-b4f3-f46f0522f80f/5a582c55-5305-44d2-8833-017fd76ba978.gltf", //S608
        },
        'Flange 2"': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/58a78656-4c57-4972-971c-24f140e45332/ab84172d-a260-4b41-bcd6-2161ef7728d1.gltf",
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/5d221860-ffb2-4e21-8a92-1897b31a1b3a/7acf11ef-e246-40d5-bb79-9cced15c160f.gltf",
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/fcfed274-8f23-4bc7-ab73-443ea2596a53/c74c8ae1-fc1b-4b52-927d-c3ffe6cef9d3.gltf",
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c738ed26-71ec-4cd9-a927-d9ed8fd7066f/05f9dcb5-1e77-4754-bbae-0f54ffab84e5.gltf", //S603
        },
        Welt: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4c8fdc28-ecb5-468d-9558-ad676572de19/b2250bbd-66a7-48e5-8667-a92b445a7818.gltf",
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/945ccdea-27c1-4585-9ceb-ff42479a2a21/e096e15e-3a06-4b96-8525-61b42b30a92e.gltf",
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ef7678ca-155d-4ad6-8869-bb93f4f5c9b9/46eeefea-3259-4ea7-97c6-ae25a4e02f36.gltf",
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c8fd481b-f4e3-4cea-accc-0b7d47986c14/6f2a6328-0028-4972-bbad-2aaca9dfc532.gltf", //S605
        },
        "4Side": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4cb9d63b-9bd3-4d71-918b-20dd1ffe7a0d/020a400d-d465-47cc-86ce-bc94cdc5f4b5.gltf",
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/64a4a179-9099-4ad7-89df-826b9b3b2279/3e23e3b0-9a2e-4ee1-97af-a699f285e96d.gltf",
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/931a19ab-345d-4a3f-b07d-f6ecd2569f55/30f83299-1299-4077-b127-7b549338edd6.gltf",
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/64a107e0-a2b3-4edb-90c6-e128db42ce32/84f76ad1-c169-42ce-98a2-5653571405b4.gltf", //S604
        },
        "2Side": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b6e2e1be-90aa-4255-a4cd-1497405974a2/4807c307-1a74-4d79-802b-ae94851c37a7.gltf",
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/32283f12-bc97-48e5-8357-39816af64dd8/c0e1d95a-6244-4a03-b4d8-955c1cb86997.gltf",
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b1c57d5f-c200-4455-9f23-c957e868012e/14eb414b-03de-4d8b-b0f4-2709c7b0cf62.gltf",
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6282cf4a-e66d-40a4-b978-960fda000259/bff91e26-7eaf-4005-af48-52f5234efa5f.gltf", //S604
        },
      },
      DoubleBand:
        "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bf42680d-40c8-4b04-8d57-704259a7dec0/0efb4300-ac33-4034-a65c-922050e2e491.gltf",
      InsetBand:
        "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/42d3567d-e610-4062-8915-e53e4289ee61/f25ef1f9-4d69-41dd-98dc-cec84790fde3.gltf", //S606
      SingleBand:
        "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/5f565390-6ae9-4f3c-9786-16c62de9cc7c/c2351c4e-6a8e-464c-8499-2efbfc189a10.gltf",
    },
    "12x20": {
      id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/90fa5f91-0a1c-4eb4-b468-36eddf6ed38d/6dbc301f-1310-4cf0-8d0e-72857b194a41.gltf",
      border: {
        'Flange 1"': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/14bbb146-c92a-4259-8648-66182f7e9bc7/c433f302-2988-4155-a4ee-617d693cdf3e.gltf", //	S352
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8c0a5a3e-bb8c-4dd9-a587-f060f278ef48/7d8e2caf-8fb3-4094-9de3-360fff2a2a48.gltf", //	S596
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/53c74f59-cf95-4bdc-9d3e-a140a4a061b9/3ccbba4d-2ae0-496d-860d-fa80d43b6502.gltf", //	S602
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/45dc14d9-db5a-4393-ac0f-4f4fb35ed350/2b25b9ae-64ac-4521-8bd3-5e3d69b6a7a3.gltf", //	S598
        },
        'Flange 2"': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a2d92635-2853-47f4-8b8a-ae2a4e1c3c55/f8a063f9-16dd-4dbc-a237-652b60f0a1fb.gltf",	 //S357
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8754eea9-d75d-4139-83e7-65b2f5a6118a/c94a5a09-967a-443d-a141-e97d4ffd73a2.gltf", //	S597
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4304c2e5-24ae-4702-b7da-e06fe59a595b/7854b8bb-537b-422e-97b2-5250919ee502.gltf", //	S603
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bd2c7f27-34d5-42e6-8304-d61ec2e338b5/ae9d2d34-af26-4205-8d87-07b0115e9527.gltf",	 //S599
        },
        Welt: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d97208de-4796-4c22-8eaf-1f716995b4c7/f5044e14-a0cb-447e-bd64-1287999144b8.gltf", //	S363
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/9697571e-9ef7-4227-8998-88d5c35401fd/7c982c31-952e-4ee3-8de7-0eae48be3329.gltf", //	S598
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/71472484-7a1e-4ff8-affc-bd8a75dda719/07afffa4-6da6-46cd-a98e-22ac646186f4.gltf", //	S606
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e626b0e8-1413-4bfc-9380-1bd70cec9215/a8d77540-d5b1-493c-bebf-46bf607d98dc.gltf", //	S603
        },
        "4Side": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e0120304-ca86-4b58-9804-0122405b5bad/2046da44-ac50-49bb-8c7e-c680f228f7ee.gltf", //s516
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2c505a30-80ea-475b-9014-feb5861692c1/e74d18b8-8d5f-4037-94b5-b930a62ccded.gltf", // S600
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f1b5e1b2-a3f9-4192-9409-c9491dd79277/8e234da9-4f55-4511-9504-754cfc946263.gltf", // S605
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/966cffa1-cc7d-47a2-b8d4-4c55788f8748/39179f9f-fc41-479e-a5b6-702943f8fed9.gltf", // S600
        },
        "2Side": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ee1e12b6-841c-42c5-a556-3deace8e3e4c/5b60abf8-27af-4006-abe9-fafa652532c0.gltf", // S520
          DoubleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/577bc5e7-352e-4d6a-8f59-500144d76665/e9a3410d-40f7-4625-9b7d-dc0562d5b860.gltf", // S599
          SingleBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e717d57f-de03-4d5d-a04d-c0b9f94b19c5/77ead649-9567-4d87-a173-c12d6a66967e.gltf", // S604
          InsetBand:
            "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/1e96ffca-e806-4c3d-a6c4-69eb1959eb1a/8c484151-f1ef-4c9a-b22f-b80eb8412a36.gltf", // S602
        },
      },
      DoubleBand:
        "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/af5274b1-4030-4d68-b7b1-5d0396bd648e/a877f4d1-6c76-467c-9a6f-c642a22885f1.gltf", // S601
      InsetBand:
        "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/fe87a3a3-fc0c-40b3-9f70-dffbad57e7ec/cb7b02ec-162c-406e-b4aa-245ba7ce6797.gltf", // S601
      SingleBand:
        "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/35db2a31-b377-46d1-9533-3fb2067748f8/a46f6955-a449-4951-a743-282b10e0e65b.gltf",  //S607
    },
  },
};

export const DraperyModelData = {
  1: {
    id: "https://app.poly9.ai/public/5feac293447a060008714d9f/3dProduct/styled-GLTF/938f31db-c24a-40e8-a657-882b34dff90a/dd1f9d97-b478-4c15-9636-95517bf54614.gltf",
  },
  2: {
    id: "https://app.poly9.ai/public/5feac293447a060008714d9f/3dProduct/styled-GLTF/938f31db-c24a-40e8-a657-882b34dff90a/dd1f9d97-b478-4c15-9636-95517bf54614.gltf",
  },
};

export const WaxFinishAlphaData = [
  {
    name: 'Clear Wax',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Clear%20Wax.png',
    exposure: 0.7,
    code: "42049056866556"
  },
  {
    name: 'Brown Wax',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Vintage%20Detailed%20Dresser%20-%20Brown%20Wax.png',
    exposure: 0.7,
    code: "42065762124028"
  },
  {
    name: 'Black Wax',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Black%20Wax.png',
    exposure: 0.7,
    code: "20823397793851"
  },
  {
    name: 'White Wax',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Winter%20White%20Glaze.png',
    exposure: 0.7,
    code: "31113995026491"
  },
]

export const GlazeFinishAlphaData = [
  {
    name: 'No Glaze',
    image: 'https://customer.polynine.com/poc/suitepieces/img/no-glaze.png',
    exposure: 0.7,
    code: ""
  },
  {
    name: 'Van Dyke Brown Glaze',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Van%20Dyke%20Brown%20Glaze.png',
    exposure: 0.7,
    code: "17611781341243"
  },
  {
    name: 'Pitch Black Glaze',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Pitch%20Black%20Glaze.png',
    exposure: 0.7,
    code: "17611851563067"
  },
  {
    name: 'Winter White Glaze',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Winter%20White%20Glaze.png',
    exposure: 0.7,
    code: "17612100862011"
  },
  {
    name: 'Smokey Gray Glaze',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Smokey%20Gray%20Glaze.png',
    exposure: 0.7,
    code: "32985988268091"
  },
]
// "tallBase", "tallAlpha", "tallDark", "tallWhite"
export const normalAlpha = {
  'Vintage_Dresser': {
    'Brown Wax': 'Vintage_Dresser/Brown Wax/Normal.jpg',
    'Black Wax': 'Vintage_Dresser/alphaimg/Normal.jpg',
    'White Wax': 'Vintage_Dresser/White Wax/Normal.jpg',
  },
  'Tall_Vintage_Dresser': {
    'Brown Wax': 'Tall_Vintage_Dresser/Brown Wax/Normal.jpg',
    'Black Wax': 'Tall_Vintage_Dresser/alphaimg/Normal.jpg',
    'White Wax': 'Tall_Vintage_Dresser/White Wax/Normal.jpg',
  },
  'Vintage_Nightstand': {
    'Brown Wax': 'Vintage_Nightstand/Brown Wax/Normal.jpg',
    'Black Wax': 'Vintage_Nightstand/alphaimg/Normal.jpg',
    'White Wax': 'Vintage_Nightstand/White Wax/Normal.jpg',
  },
  'Long_Vintage_Credenza': {
    'Brown Wax': 'Long_Vintage_Credenza/Brown Wax/Normal.jpg',
    'Black Wax': 'Long_Vintage_Credenza/alphaimg/Normal.jpg',
    'White Wax': 'Long_Vintage_Credenza/White Wax/Normal.jpg',
  },
  'Vintage_Buffet': {
    'Brown Wax': 'Vintage_Buffet/Brown Wax/Normal.jpg',
    'Black Wax': 'Vintage_Buffet/alphaimg/Normal.jpg',
    'White Wax': 'Vintage_Buffet/White Wax/Normal.jpg',
  },
}

export const waxArray = {
  1: {
    "alpha": "Vintage_Dresser/Clear Wax/1-Alpha.png",
    "base": "Vintage_Dresser/Clear Wax/0-Base.jpg",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/Clear Wax/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/Clear Wax/0-Base.jpg",
    "longAlpha": "Long_Vintage_Credenza/Clear Wax/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/Clear Wax/0-Base.jpg",
    "buffetAlpha": "Vintage_Buffet/Clear Wax/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/Clear Wax/0-Base.jpg",
    "nightAlpha": "Vintage_Nightstand/Clear Wax/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/Clear Wax/0-Base.jpg",
  },
  2: {
    "alpha": "Vintage_Dresser/Brown Wax/1 - Alpha.png",
    "base": "Vintage_Dresser/Brown Wax/0 - Base Material.jpg",
    "dark": "Vintage_Dresser/Brown Wax/2-Black Mask.png",
    // "dark": "Vintage_Dresser/Brown Wax/2 - Dark Lines.png",
    // "white": "Vintage_Dresser/Brown Wax/3 - White Lines.png",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/Brown Wax/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/Brown Wax/0-Base.jpg",
    "tallDark": "Vintage_Tall_Dresser_ Materials/Brown Wax/2-Black Mask.png",
    // "tallDark": "Vintage_Tall_Dresser_ Materials/Brown Wax/2-Black Lines.png",
    // "tallWhite": "Vintage_Tall_Dresser_ Materials/Brown Wax/3-White Lines.png",
    "longAlpha": "Long_Vintage_Credenza/Brown Wax/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/Brown Wax/0-Base.jpg",
    "longDark": "Long_Vintage_Credenza/Brown Wax/2-Black Mask.png",
    // "longDark": "Long_Vintage_Credenza/Brown Wax/2-Black Lines.png",
    // "longWhite": "Long_Vintage_Credenza/Brown Wax/3-White Lines.png",
    "buffetAlpha": "Vintage_Buffet/Brown Wax/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/Brown Wax/0-Base.jpg",
    "buffetDark": "Vintage_Buffet/Brown Wax/2-Black Mask.png",
    // "buffetDark": "Vintage_Buffet/Brown Wax/2-Black Lines.png",
    // "buffetWhite": "Vintage_Buffet/Brown Wax/3-White Lines.png",
    "nightAlpha": "Vintage_Nightstand/Brown Wax/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/Brown Wax/0-Base.jpg",
    "nightDark": "Vintage_Nightstand/Brown Wax/2-Black Mask.png",
    // "nightDark": "Vintage_Nightstand/Brown Wax/2-Black Lines.png",
    // "nightWhite": "Vintage_Nightstand/Brown Wax/3-White Lines.png",
  },
  3: {
    "alpha": "Vintage_Dresser/alphaimg/1-Alpha.png",
    "base": "Vintage_Dresser/alphaimg/0-Base.jpg",
    "dark": "Vintage_Dresser/alphaimg/2-Black Mask.png",
    // "dark": "Vintage_Dresser/alphaimg/2-Black Lines.png",
    // "white": "Vintage_Dresser/alphaimg/3-White Lines.png",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/Black Wax/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/Black Wax/0-Base.jpg",
    "tallDark": "Vintage_Tall_Dresser_ Materials/Black Wax/2-Black Mask.png",
    // "tallDark": "Vintage_Tall_Dresser_ Materials/Black Wax/2-Black Lines.png",
    // "tallWhite": "Vintage_Tall_Dresser_ Materials/Black Wax/3-White Lines.png",
    "longAlpha": "Long_Vintage_Credenza/Black Wax/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/Black Wax/0-Base.jpg",
    "longDark": "Long_Vintage_Credenza/Black Wax/2-Black Mask.png",
    // "longDark": "Long_Vintage_Credenza/Black Wax/2-Black Lines.png",
    // "longWhite": "Long_Vintage_Credenza/Black Wax/3-White Lines.png",
    "buffetAlpha": "Vintage_Buffet/Black Wax/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/Black Wax/0-Base.jpg",
    "buffetDark": "Vintage_Buffet/Black Wax/2-Black Mask.png",
    // "buffetDark": "Vintage_Buffet/Black Wax/2-Black Lines.png",
    // "buffetWhite": "Vintage_Buffet/Black Wax/3-White Lines.png",
    "nightAlpha": "Vintage_Nightstand/Black Wax/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/Black Wax/0-Base.jpg",
    "nightDark": "Vintage_Nightstand/Black Wax/2-Black Mask.png",
    // "nightDark": "Vintage_Nightstand/Black Wax/2-Black Lines.png",
    // "nightWhite": "Vintage_Nightstand/Black Wax/3-White Lines.png",
  },
  4: {
    "alpha": "Vintage_Dresser/White Wax/1-Alpha.png",
    "base": "Vintage_Dresser/White Wax/0-Base.jpg",
    "dark": "Vintage_Dresser/White Wax/2-Black Mask.png",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/White Wax/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/White Wax/0-Base.jpg",
    "tallDark": "Vintage_Tall_Dresser_ Materials/White Wax/2-Black Mask.png",
    "longAlpha": "Long_Vintage_Credenza/White Wax/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/White Wax/0-Base.jpg",
    "longDark": "Long_Vintage_Credenza/White Wax/2-Black Mask.png",
    "buffetAlpha": "Vintage_Buffet/White Wax/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/White Wax/0-Base.jpg",
    "buffetDark": "Vintage_Buffet/White Wax/2-Black Mask.png",
    "nightAlpha": "Vintage_Nightstand/White Wax/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/White Wax/0-Base.jpg",
    "nightDark": "Vintage_Nightstand/White Wax/2-Black Mask.png",
  }
}
export const glazeArray = {

  1: {
    "alpha": "Vintage_Dresser/No Glaze/1-Alpha.png",
    "base": "Vintage_Dresser/No Glaze/0-Base.jpg",
    // "dark": "Vintage_Dresser/No Glaze/2-White Lines.png",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/No Glaze/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/No Glaze/0-Base.jpg",
    // "tallDark": "Vintage_Tall_Dresser_ Materials/No Glaze/2-White Lines.png",
    "longAlpha": "Long_Vintage_Credenza/No Glaze/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/No Glaze/0-Base.jpg",
    // "longDark": "Long_Vintage_Credenza/No Glaze/2-White Lines.png",
    "buffetAlpha": "Vintage_Buffet/No Glaze/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/No Glaze/0-Base.jpg",
    // "buffetDark": "Vintage_Buffet/No Glaze/2-White Lines.png",
    "nightAlpha": "Vintage_Nightstand/No Glaze/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/No Glaze/0-Base.jpg",
    // "nightDark": "Vintage_Nightstand/No Glaze/2-White Lines.png",
  },
  2: {
    "alpha": "Vintage_Dresser/Van Dyke Brown/1-Alpha.png",
    "base": "Vintage_Dresser/Van Dyke Brown/0-Base.jpg",
    // "dark": "Vintage_Dresser/Van Dyke Brown/2-White Lines.png",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/Van Dyke Brown/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/Van Dyke Brown/0-Base.jpg",
    // "tallDark": "Vintage_Tall_Dresser_ Materials/Van Dyke Brown/2-White Lines.png",
    "longAlpha": "Long_Vintage_Credenza/Van Dyke Brown/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/Van Dyke Brown/0-Base.jpg",
    // "longDark": "Long_Vintage_Credenza/Van Dyke Brown/2-White Lines.png",
    "buffetAlpha": "Vintage_Buffet/Van Dyke Brown/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/Van Dyke Brown/0-Base.jpg",
    // "buffetDark": "Vintage_Buffet/Van Dyke Brown/2-White Lines.png",
    "nightAlpha": "Vintage_Nightstand/Van Dyke Brown/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/Van Dyke Brown/0-Base.jpg",
    // "nightDark": "Vintage_Nightstand/Van Dyke Brown/2-White Lines.png",
  },
  3: {
    "alpha": "Vintage_Dresser/Pitch Black Glaze/1-Alpha.png",
    "base": "Vintage_Dresser/Pitch Black Glaze/0-Base.jpg",
    // "dark": "Vintage_Dresser/Pitch Black Glaze/2-Pitch Lines.png",
    // "white": "Vintage_Dresser/Pitch Black Glaze/2-White Lines.png",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/Pitch Black Glaze/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/Pitch Black Glaze/0-Base.jpg",
    // "tallDark": "Vintage_Tall_Dresser_ Materials/Pitch Black Glaze/2-White Lines.png",
    // "tallWhite": "Vintage_Tall_Dresser_ Materials/Pitch Black Glaze/3-White Lines.png",
    "longAlpha": "Long_Vintage_Credenza/Pitch Black Glaze/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/Pitch Black Glaze/0-Base.jpg",
    // "longDark": "Long_Vintage_Credenza/Pitch Black Glaze/2-White Lines.png",
    // "longWhite": "Long_Vintage_Credenza/Pitch Black Glaze/3-White Lines.png",
    "buffetAlpha": "Vintage_Buffet/Pitch Black Glaze/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/Pitch Black Glaze/0-Base.jpg",
    // "buffetDark": "Vintage_Buffet/Pitch Black Glaze/2-White Lines.png",
    // "buffetWhite": "Vintage_Buffet/Pitch Black Glaze/3-White Lines.png",
    "nightAlpha": "Vintage_Nightstand/Pitch Black Glaze/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/Pitch Black Glaze/0-Base.jpg",
    // "nightDark": "Vintage_Nightstand/Pitch Black Glaze/2-White Lines.png",
    // "nightWhite": "Vintage_Nightstand/Pitch Black Glaze/3-White Lines.png",
  },
  4: {
    "alpha": "Vintage_Dresser/Winter White Glaze/1-Alpha.png",
    "base": "Vintage_Dresser/Winter White Glaze/0-Base.jpg",
    // "dark": "Vintage_Dresser/Winter White Glaze/2-White Lines.png",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/Winter White Glaze/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/Winter White Glaze/0-Base.jpg",
    // "tallDark": "Vintage_Tall_Dresser_ Materials/Winter White Glaze/2-White Lines.png",
    "longAlpha": "Long_Vintage_Credenza/Winter White Glaze/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/Winter White Glaze/0-Base.jpg",
    // "longDark": "Long_Vintage_Credenza/Winter White Glaze/2-White Lines.png",
    "buffetAlpha": "Vintage_Buffet/Winter White Glaze/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/Winter White Glaze/0-Base.jpg",
    // "buffetDark": "Vintage_Buffet/Winter White Glaze/2-White Lines.png",
    "nightAlpha": "Vintage_Nightstand/Winter White Glaze/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/Winter White Glaze/0-Base.jpg",
    // "nightDark": "Vintage_Nightstand/Winter White Glaze/2-White Lines.png",
  },
  5: {
    "alpha": "Vintage_Dresser/Smokey gray glaze/1-Alpha.png",
    "base": "Vintage_Dresser/Smokey gray glaze/0-Base.jpg",
    // "dark": "Vintage_Dresser/Smokey gray glaze/2-White Lines.png",
    "tallAlpha": "Vintage_Tall_Dresser_ Materials/Smokey gray glaze/1-Alpha.png",
    "tallBase": "Vintage_Tall_Dresser_ Materials/Smokey gray glaze/0-Base.jpg",
    // "tallDark": "Vintage_Tall_Dresser_ Materials/Smokey gray glaze/2-White Lines.png",
    "longAlpha": "Long_Vintage_Credenza/Smokey gray glaze/1-Alpha.png",
    "longBase": "Long_Vintage_Credenza/Smokey gray glaze/0-Base.jpg",
    // "longDark": "Long_Vintage_Credenza/Smokey gray glaze/2-White Lines.png",
    "buffetAlpha": "Vintage_Buffet/Smokey gray glaze/1-Alpha.png",
    "buffetBase": "Vintage_Buffet/Smokey gray glaze/0-Base.jpg",
    // "buffetDark": "Vintage_Buffet/Smokey gray glaze/2-White Lines.png",
    "nightAlpha": "Vintage_Nightstand/Smokey gray glaze/1-Alpha.png",
    "nightBase": "Vintage_Nightstand/Smokey gray glaze/0-Base.jpg",
    // "nightDark": "Vintage_Nightstand/Smokey gray glaze/2-White Lines.png",
  }
}

export const UpholsteryData = {

  '1111': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0f4151bd-1123-4ebe-98a6-876c094427c7/9eabbc5e-5765-4287-9993-22ba794341b4.gltf',
  '1112': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8235d997-74f7-4a70-9cf7-5edc190affed/5986183e-a24f-41e5-849a-0c74a7026d9c.gltf',
  '1113': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b493fb92-4966-4210-986f-06b81302fc2a/201c185d-4c9b-4729-9a52-b8fbe8c81ac7.gltf',

  '1121': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d37682e3-2baa-456f-86d0-599a0cc14422/2747cc22-0d2c-4a80-b166-bcb503a0b766.gltf',
  '1122': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3021aa45-f8c0-4c0c-84a8-18c18b426fef/b76f9be9-1a9c-4800-bd32-8639d58c7c89.gltf',
  '1123': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/dce7e78b-b2c4-447a-aed9-af69c588dc76/4af95890-887a-4e85-b083-e71f12ec2837.gltf',

  '1131': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/1f6624b7-6154-49d7-a324-132341aaf4e0/695ec4a0-c160-4f21-92e8-431168dcd8ac.gltf',
  '1132': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/185220a2-5af8-45eb-9479-240193106102/a8ed4817-9323-4ac1-ba20-86d884f8934c.gltf',
  '1133': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b145384f-f8aa-4222-951a-503f8175564b/6e641965-0de6-4ffa-9f7f-d67c8eb6269d.gltf',

  '1211': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6c780dad-ce14-4cc9-865d-08a1b00bcee9/debfb18d-aaea-4be9-a02c-d3e846ab7171.gltf',
  '1212': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4473de77-fd65-44d6-880e-c47cf215ef1f/426cd2ff-208e-4761-9d1d-65dde7ae9bfd.gltf',
  '1213': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8dead729-972e-41c6-a4a2-0b732611834b/39eee1c6-199a-4158-a9ef-6eb4e8e1d8dd.gltf',

  '1221': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/172ee1b0-0ada-40b2-94f2-b5c3427c2441/27947a11-7bea-44c3-870d-a252485abea1.gltf',
  '1222': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bca7eaec-3dcd-4110-8058-01e3f0bf2cfe/245435ae-a02e-45cc-8882-f04919c4e1c7.gltf',
  '1223': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/dc5ab4ac-dbe1-4b29-becd-e1216337f8c8/b4b0df68-961d-4beb-871d-3bb9df7f00e5.gltf',

  '1231': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/98f331f1-32f0-45ac-b5a7-59cd9cd71af2/8138c1ee-7afc-4d7a-a2a4-4cb960e8774a.gltf',
  '1232': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/874bba94-37a4-49d4-abe7-db95a069636a/e160fed4-1f52-436c-9f6b-1fd4b37ef3ff.gltf',
  '1233': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d4d0c5c1-87be-45d7-9742-7ac6031370f2/751b39b5-dc05-4a2f-8d2e-ab566e6e95e2.gltf',

  '1141': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/452c184e-f006-43cc-958b-84a6d02b359d/933a09a5-ea4d-4be8-b342-bee8f7d3465f.gltf',
  '1142': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f40f3e92-7e06-4d56-a13a-166ee00c7979/87148587-feef-432d-b4f9-2d767d375bd8.gltf',
  '1143': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0a1eb4b2-db41-48ef-b8ad-cea1cd0b1d5c/f5ee6024-ae8e-4fc8-bf59-692ffb0d49b6.gltf',

  '1241': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0c62aa69-0b17-4f7b-bda9-75e41dd12df0/466063a0-881f-44bf-97a9-cc7debe907f7.gltf',
  '1242': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8d1401fd-e7dd-4c93-9912-c1a3d0835c4b/c6a75208-479b-4baa-b21f-dc6a17cc8c03.gltf',
  '1243': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/710a11ee-f7cb-4ca6-a357-bc941b5b2b47/749e8553-a50d-4a0e-b23c-4d0484c253a6.gltf',

  '1311': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0c96fdb7-cf4e-4276-85f5-a7281116404b/3fac69d2-2469-4a88-9b18-82f39cd63b84.gltf',
  '1312': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b639811b-e3f8-4595-802d-58d30de92057/f66e5cce-a705-4441-a652-a56be3973e5e.gltf',
  '1313': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/30609602-f577-4eac-a51e-39db08eaaf29/f0b038ac-e33e-44ea-936b-05e693a785f6.gltf',

  '1321': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f96e45fa-2dcf-437e-8222-60761dcdd80c/c873da69-ebfc-4cb0-ad36-62437bf71073.gltf',
  '1322': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c68c7974-0416-4dec-851e-0ab22b09b3b1/96c46cb6-1adf-4b5f-a637-bf71a24f88ce.gltf',
  '1323': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/60035568-10e9-45e1-a3f3-e60d54a1dcb5/9cca3e56-41a3-470d-b7ea-8f51bb4e7bb3.gltf',

  '1331': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/9cc930df-665e-4e8e-81bb-a2743cfe10f5/13f0def4-8141-4456-adab-f25244a577d0.gltf',
  '1332': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/948deb4b-d3a5-4133-bbaa-16bc7bb234db/d317ae73-a83e-4843-b4cd-053d2d97f9d3.gltf',
  '1333': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e76d09f2-30a6-4eb3-8494-06020c216cb0/2d348dc9-b97c-4b73-ac49-512cc57561c4.gltf',

  '1341': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/cfd44946-f377-4b47-844c-d634549030b7/008df200-283a-4e06-be4e-de0a809c0501.gltf',
  '1342': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b49aa0f8-0868-433b-b345-e552ba907105/3d580fdb-8974-4dae-a9db-b71a83dc80e8.gltf',
  '1343': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b27f6a6d-2ef1-4654-9074-60ed1da980f3/ed4eba00-d2d6-4618-bd6e-f5d055035702.gltf',

  // 2nd Batch
  '2111': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/733993fb-395f-4fa3-b03f-3602a1d3a6f1/947c2f64-e7d3-433d-97c4-22830a5f0f8f.gltf',
  '2112': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/49cde959-c7f6-43f4-9bab-8bf1713ee76e/47e638bb-1749-4c35-b506-9b25ebcf6611.gltf',
  '2121': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/66113efb-d5d6-4a3c-a0f9-6ea4c2fc068b/0b7fbe5f-ecde-4de4-93be-5561da2e5c20.gltf',
  '2122': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/730ac283-f2f8-4ea5-a17d-d955cfe8dfe8/d5b9ce25-e4a6-4254-adfc-f872b91b6d83.gltf',
  '2211': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/afd876bb-0b57-4e70-b111-e819a9fd2acf/ea4e3e1d-ea37-401d-a2f0-43cf921a75d5.gltf',
  '2212': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/174945ba-029c-42c2-a4f6-49a674bf7e15/24708950-1280-4042-b00a-138a332607aa.gltf',
  '2221': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4ff8a1ed-90d7-42d6-988a-521fc6b46fa9/4454cbd7-898a-42c1-8304-b2f2bb3cc903.gltf',
  '2222': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/87bf30d7-3489-4052-bc09-b270aed572af/77d495f6-3d69-461c-a369-f4bc2551f644.gltf',

  '2312': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8b66d24e-12d2-4a23-b5ae-7a32aab9fc30/45ae8d48-5e75-4076-98cd-bad5a1b59e79.gltf',
  '2313': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/dc281811-3ad8-4487-89f2-855351c3bf01/67e374ff-617c-4081-a8b1-4a165bbf504f.gltf',
  '2321': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/059be6de-cf9a-4ed3-835d-5792f5b100dd/70a93ace-d53f-4ac6-8c35-b506e99f3e1a.gltf',
  '2322': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d1186ee8-523c-4f34-aea8-f82f6664fb83/7e8f566c-b02b-4c5a-85c1-31b2a2cb5e80.gltf',
  '2323': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ed9d4ce8-ef71-4ee0-8b21-544fb93f355e/a4ab55f9-afd1-4ab1-81ff-02a86a77a21c.gltf',
  '2331': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f56e0c1a-718e-4e2f-92e1-8f026f60fe97/a94c4c6b-26e2-45b8-ad34-cb907e89ae02.gltf',
  '2332': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6bd94f52-9d3a-4cc5-8162-d3c3ccbd59bc/8233f1b6-baea-4ff0-ac9c-104c67d02602.gltf',
  '2333': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/46af4200-4528-4097-8450-e0bdebe082c2/29728685-48f1-48ff-8d1e-f74187e50a23.gltf',
  '2341': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/aa4f59d8-df9d-4cab-b480-15f635579dba/f219b4f4-aada-4fd7-a317-b17dd788b9e4.gltf',
  '2342': ' https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/fadb47b5-e8c3-4b9d-acf5-bf7cb0cb0372/0cffc827-600c-4b41-8b79-ed77c3d759e5.gltf',
  '2343': ' https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a924642f-a151-4067-b5b9-79b2062b8cfa/dbdfc1aa-20aa-4861-9230-75f31442a18b.gltf',

  '2113': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/20ba4c13-7c43-4aa2-b0f8-22aa59006cc1/d0147216-b5b9-4051-963c-f20406ffd2a6.gltf',
  '2123': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/320ac8cf-32a1-41e4-806b-bd2cd2d48fd6/637d6c12-9c4d-4750-ae80-85489a999556.gltf',
  '2131': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/667163aa-4dda-4ee2-99e7-58aaa9d85972/16fe71a9-7c04-43ad-a121-1c7f7b96c239.gltf',
  '2132': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b2024cc7-4e92-456c-a78c-a9933e7a4a49/f58472ff-22ac-4ff7-bac6-eb0a98187718.gltf',
  '2133': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/29e1b457-a11a-42d9-b950-35f3fe2f0c8c/b91367f4-033d-42bd-bcea-d8833e8fa61b.gltf',
  '2141': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/21b398d4-3378-4ff8-9e3a-70dafe00077e/883473d6-c084-4bf4-9f40-c37ea39dacaf.gltf',
  '2142': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d6ca8165-c87c-4449-9918-f84e994e2cf8/693fed5e-8d31-4cd4-8692-1c1597f8160b.gltf',
  '2143': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0d47da7b-3128-4d39-8fdf-f83585427ad7/e0f00635-689a-42f8-92bf-26ca0999a420.gltf',
  '2213': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ff50da88-af1d-4b81-a4b5-890c1993538f/921e6e05-4aba-4505-816a-67e927cb8579.gltf',

  '2223': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/454d2b5d-6876-4d1e-873c-7adcf4451cec/c833bdf6-5fb3-449a-bb52-8ef52a690a76.gltf',
  '2231': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/09422654-57bf-4c6d-8b4c-c4b7f26277a5/9cbe4b2e-0a8c-4e0c-94a6-95d14eb65777.gltf',
  '2232': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/db7a666a-c2dd-4147-9af8-deee6d8bbc2d/e4d51fe7-b653-4110-bdf7-024baee2ec8e.gltf',
  '2233': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a31a1564-8e92-4c0c-a276-430b650ba313/514c7aed-02bc-4734-91da-c5fcad61a620.gltf',
  '2241': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/13043f36-3161-4c05-839d-5658af8fb14f/8d03b58f-eb01-4dae-b1a4-0dda1ed52ea6.gltf',
  '2242': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/153ca3f1-4e0e-4c3c-ae3a-afe3f9bb969d/82f72a82-dedb-4843-b0d4-820e1e5610fd.gltf',
  '2243': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/aabdd7a4-6419-40d3-85a4-5a80150655c4/7570e53f-f02e-4c80-be62-a1eff14aee4b.gltf',
  '2311': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7d0cf16e-de03-464b-b620-c04817477811/8db3f6b0-b354-427a-ab88-a60af9d15584.gltf',

  // 3rd Batch

  '3111': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c95bc116-a903-415e-abd2-e06583295205/5a61c485-82eb-487d-8f01-2740934c0ed4.gltf',
  '3112': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/aea40528-057a-4242-9a0b-eec35c475494/3db9d516-01bb-4d75-9551-141ac8244957.gltf',
  '3113': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f0f5d992-8201-446b-bf4f-f6a58939ca23/76823627-02dc-4625-b6c1-80d77cc32fe3.gltf',
  '3121': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a8600c30-3fab-452a-ba6d-d3bd606dafc6/716bcd32-e80b-4a86-ba41-f2a612781ecf.gltf',
  '3122': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/54af7050-bfc8-4647-bee7-279acecdf7ab/2f91d17f-54a3-4d2e-b77a-9dc60a1525c3.gltf',
  '3123': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/09b95b3c-d641-440a-9c52-64c689a27f43/18940e53-1c61-46cd-9763-608783c57e98.gltf',
  '3131': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c7bcd751-7710-4e92-a723-dbdd2617a051/760c6a37-7b18-4940-9c08-efc2fae0bc7d.gltf',
  '3132': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4cdc7ce7-9d9b-42cc-9ed8-0bd35801c04c/671bc724-f222-46af-84f7-4694a1effac6.gltf',
  '3133': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c826f73e-3f44-490b-a626-cde95a18bf63/1d94eb7f-092d-450a-9202-a2d8afec0934.gltf',
  '3141': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/84165ac8-bcfc-4162-bb50-461353767361/94081b4a-b70c-4526-8c8f-5cd6baea1b2e.gltf',
  '3142': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8280cbf0-9caf-4a89-b93d-3f1078598e2d/44a6ee62-c298-4f5b-a70c-6d872791472b.gltf',

  '3143': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e19d6aac-7249-4497-b552-2c3a59d2524e/d61b43e2-cea3-4462-95c9-c66cf748d28c.gltf',
  '3211': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0d415ee3-edff-45ab-b06e-8e2295c47a88/b7ba4090-ba9b-466a-8487-c1b36bfc629e.gltf',
  '3212': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d31a0581-914c-48b7-ade4-dd3bcd1d60b4/8dcd0a6c-1ecf-4107-8bef-9f2fab158af3.gltf',
  '3213': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/db731b86-0661-47ec-9406-4d649809419e/1e3a52e9-1010-48af-9bba-6f4ab349027d.gltf',
  '3221': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/062c45f7-8b74-477e-ac34-be93d1032194/65b37792-3bce-4a20-bfe1-67b9f56e4906.gltf',
  '3222': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4b93cd95-cbad-457c-9f6f-09c7d315c134/77b2c7ed-6537-4ecd-b05e-802eb38318ab.gltf',
  '3223': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c2e66c7b-eff0-45b6-8035-1bd0da791466/088cce8c-63b6-408b-ba7c-66cffc0624b8.gltf',
  '3231': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/eff8a2ae-33da-40e8-b3e8-6be828dbba36/7d79f072-003e-4092-a988-72593b2da568.gltf',
  '3232': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6584673b-bfaa-4d84-a768-4680c87e5a06/4f1423eb-197e-4712-a25e-2ed1d60d15ac.gltf',
  '3233': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/191afc7f-1256-403d-abb4-2d52774cc549/30129885-0836-426d-9050-9102e61a69a8.gltf',

  '3241': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/fa348fbc-689c-4a97-a3fc-810cbe12aaa0/8d6c7b63-8d5b-454d-b46c-ff3abef454c8.gltf',
  '3242': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/9b847333-4ad1-4c5a-a036-388a111b230b/d052c2a6-5bec-4b68-a8f8-d0cc1779c1b5.gltf',
  '3243': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/fd42ad7b-5601-4dde-b227-fc4ac6075214/c286ec7d-28da-4b26-870d-40ffaf5a0673.gltf',
  '3311': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0292ccf6-83b7-4637-b7be-4d4a5ca068cf/5fd72025-e092-4873-8a07-ba97bc6873ea.gltf',
  '3312': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a845b63d-a16d-49d1-b341-1768dcd7b478/9ee6ef6e-efcb-4f63-ae84-107118dfc6c5.gltf',
  '3313': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/30ecbfda-b273-4b4e-bb12-abf166539a84/6e5780b3-660c-488c-acf6-2715e9210129.gltf',
  '3321': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/72b36d94-ad87-4cdb-9531-97be46d3378c/aa215693-415a-40be-8d97-6aa054b80af2.gltf',
  '3322': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8cdafe59-8b45-498d-a193-50b513903b28/407946d2-9ee6-4879-9214-7dcb6d9f40b5.gltf',
  '3323': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f6fa851a-c1ea-4403-a419-ca339e7d279d/6eba4225-f515-40a1-988e-79d69a6d7722.gltf',
  '3331': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6a25e5c6-9969-4252-a41f-c9392593f394/d7b4f015-af33-451d-9068-a2a41b73168d.gltf',
  '3332': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a288d9c2-b32d-40b1-a4b6-06d941e9b30b/a7a4af07-55f6-4030-be90-f3d1eceb6e0f.gltf',
  '3333': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/9175e9ac-5835-4991-9e36-f9c28a0ea42c/3fd82e33-46de-4d5f-bdde-b3aaeb886652.gltf',
  '3341': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7eb8d3a1-a53b-4b12-9c19-26b11138fc1b/fa5526f6-0047-4283-b679-2f41a398d064.gltf',
  '3342': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8048d46d-f479-49f0-bcea-b01e708c47fe/e3bb7091-904f-427f-bec3-1249c70031a2.gltf',
  '3343': 'https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e6f921ec-6736-4b0f-8f84-07667caf67b2/81bb1e82-31fd-4e68-8970-ed72e619b233.gltf',

}

export default content;
