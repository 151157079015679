/* eslint-disable jsx-a11y/alt-text */
import React, { createRef, useEffect, useState } from "react";
import GoogleModelViewer from "../../../components/GoogleModelViewer";
import content, { GlazeFinishAlphaData, PillowModelData, WaxFinishAlphaData } from "../../../content";
import FabricSelection from "./FabricSelection";
import { useRecoilState, useRecoilValue } from "recoil";
import { fabricAtom, modelAtom, vintageAtom, upholsteryAtom, drapryAtom, selectedAlphaAtom, tabAtom, currentCustomizationAtom, uploadImageAtom, selectedImageScaletom, showModalAtom, upholsteryItemAtom, upholsteryStatusAtom, loaderAtom, pillowSideAtom, uploadFabricAtom, addFabricToggleAtom, currentPillowStateAtom, boxDimentionAtom, beforeSelectedImageScaletom, offsetValueAtom, canvasToggleRenderAtom, selectedCustomFabricAtom, addNewCustomFabricAtom, addFabricBeforeAtom, aspectRationAtom, showBandModalAtom, addBandFabricBeforeAtom, selectedFabricBandAtom, customBandValueAtom, cropperSelectionAtom, selectedBandAtom, addBandFinalFabricBeforeAtom } from "../../../state";
import { GET_URL } from "../../../constans";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Slider } from 'antd'
import { insertType, material, pillowAllSize, subMaterial, upholsterySidebar } from "../../../lib/constant";
import PillowModelViewer from "../../../components/GoogleModelViewer/PillowModelViewer";
import { UpholsteryTabData } from "./UpholsteryTabData";
import UpholsteryModelViewer from "../../../components/GoogleModelViewer/UpholsteryModelViewer";
import CropImage from "../../../components/CropImage";
import { DebounceInput } from "react-debounce-input";
import Cropper from "react-cropper";
import BandCropImage from "../../../components/CropImage/BandCropImage";

const Customization = ({ category, selectedProduct, setSelectedProduct }) => {
    const smallSize = {
        name: "12x20",
        image: "12x20.png",
        setSize: '12x20'
    }
    const bigSize = {
        name: "20x20",
        image: "20.png",
        setSize: '20x20'
    }
    const sizeToDegree = {
        "24x24": "30deg",
        "22x22": "35deg",
        "20x20": "40deg",
        "18x18": "45deg",
        "14x24": "30deg",
        "14x20": "35deg",
        "12x24": "40deg",
        "12x20": "45deg"
    };
    const cropperRef = createRef();
    const cropperImageRef = createRef();
    const models = PillowModelData;
    const [inputValue, setInputValue] = useRecoilState(selectedImageScaletom);
    const [inputSetValue, setInputSetValue] = useRecoilState(beforeSelectedImageScaletom);
    const [modelSrc, setModelSrc] = useRecoilState(modelAtom);
    const [tab, setTab] = useRecoilState(tabAtom);
    const [fabricAtomState, setFabricsAtomState] = useRecoilState(fabricAtom);
    const [drapryAtomState, setDrapryAtomState] = useRecoilState(drapryAtom);
    const [vintageAtomState, setVintageAtomState] = useRecoilState(vintageAtom);
    const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
    const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState(upholsteryAtom);
    const [currentCustomization, setCurrentCustomization] = useRecoilState(currentCustomizationAtom);
    const [activeBand, setActiveBand] = useState("All");
    const [pillowSide, setPillowSide] = useRecoilState(pillowSideAtom);
    const [activeNainhead, setActiveNainhead] = useState("All");
    const [activeWelt, setActiveWeld] = useState("All");
    const [fabricImg, setFabricImg] = useState(null);
    const [alphaSelect, setAlphaSelect] = useRecoilState(selectedAlphaAtom)
    const [alpha, setAlpha] = useState('wax')
    const [uploadImage, setUploadImage] = useRecoilState(uploadImageAtom)
    const [showModal, setShowModal] = useRecoilState(showModalAtom)
    const [showBandModal, setShowBandModal] = useRecoilState(showBandModalAtom)
    const [upholsteryItem, setUpholsteryItem] = useRecoilState(upholsteryItemAtom)
    const [upholsteryStatus, setUpholsteryStatus] = useRecoilState(upholsteryStatusAtom)
    const [loader, setLoader] = useRecoilState(loaderAtom)
    const [uploadFabricModel, setUploadFabricModel] = useRecoilState(uploadFabricAtom);
    const [addFabricToggle, setAddFabricToggle] = useRecoilState(addFabricToggleAtom)
    const boxDimentions = useRecoilValue(boxDimentionAtom)
    const addFabricBefore = useRecoilValue(addFabricBeforeAtom);
    const [cropData, setCropData] = useState(addFabricBefore[pillowSide]);
    const [canvasToggleRender, setCanvasToggleRender] = useRecoilState(canvasToggleRenderAtom)
    const [addNewCustomFabric, setAddNewCustomFabric] = useRecoilState(addNewCustomFabricAtom)
    const [selectedCustomFabric, setSelectedCustomFabricAtom] = useRecoilState(selectedCustomFabricAtom)
    const [aspectRation, setAspectRation] = useState(aspectRationAtom)
    const [addBandFabricBefore, setAddBandFabricBefore] = useRecoilState(addBandFabricBeforeAtom);
    const [selectedFabricBand, setSelectedFabricBand] = useRecoilState(selectedFabricBandAtom);
    const [customBandValue, setCustomBandValue] = useRecoilState(customBandValueAtom)
    const [cropperData, setCropperData] = useRecoilState(cropperSelectionAtom)
    const [selectedBand, setSelectedBand] = useRecoilState(selectedBandAtom)
    const [addBandFinalFabricBefore, setAddBandFinalFabricBefore] = useRecoilState(addBandFinalFabricBeforeAtom)

    useEffect(() => {
        if (addFabricBefore[pillowSide]) {
            setCropData(addFabricBefore[pillowSide])
        }
    }, [addFabricBefore])

    const UseHeightAndWidth = async () => {
        if (uploadImage?.[pillowSide]) {
            const final_img = uploadImage?.[pillowSide]
            var image = new Image();
            image.src = final_img;
            await image.decode();
            const { height: img_height, width: img_width } = image;
            const { Vertical: vertical_repeat, Horizontal: horizontal_repeat, Scale: multiply = 1 } = inputSetValue?.[pillowSide];

            if (vertical_repeat !== 0 && horizontal_repeat !== 0) {
                let height_inch = (img_height * vertical_repeat) / boxDimentions.height
                let width_inch = (img_width * horizontal_repeat) / boxDimentions.width
                const pilloSize = currentCustomization.size.name
                const heightDimention = pilloSize === '12x20' ? 0.45 : 0.85
                const widthDimention = pilloSize === '12x20' ? 0.75 : 0.85
                let t_c_h = height_inch * heightDimention
                let t_c_w = width_inch * widthDimention
                let V = (Number(pilloSize.slice(0, 2)) / t_c_h) * multiply

                let U = (20 / t_c_w) * multiply
                if (V >= 0 && U >= 0) {
                    setInputValue({
                        ...inputValue,
                        [pillowSide]: {
                            ...inputValue[pillowSide],
                            Horizontal: U,
                            Vertical: V,
                        }
                    })
                    if (selectedCustomFabric?.id) {
                        const newData = addNewCustomFabric?.map(data => data?.id === selectedCustomFabric?.id ? {
                            ...data, ...{
                                setHorizontal: U,
                                setVertical: V,
                            }
                        } : data)
                        setAddNewCustomFabric(newData)
                    }
                }
            };
        }
    }

    useEffect(() => {
        UseHeightAndWidth()
    }, [inputSetValue?.[pillowSide], uploadImage])

    useEffect(() => {
        setTab("TAB_1");
    }, [category])

    useEffect(() => {
        switch (selectedProduct) {
            case 0:
                setCurrentCustomization({
                    ...currentCustomization,
                    size: smallSize,
                })
                break;
            case 1:
                setCurrentCustomization({
                    ...currentCustomization,
                    size: bigSize,
                })
                break;
            case 2:
                setCurrentCustomization({
                    ...currentCustomization,
                    size: smallSize,
                })
                break;
            case 3:
                setCurrentCustomization({
                    ...currentCustomization,
                    size: bigSize,
                })
                break;
            default:
                break;
        }
    }, [selectedProduct])

    const onChange = (newValue, key) => {
        const changeData = {
            ...inputSetValue,
            [pillowSide]: {
                ...inputSetValue[pillowSide],
                [key]: newValue
            }
        }
        setInputSetValue(changeData);
        setSelectedCustomFabricAtom({
            ...selectedCustomFabric,
            [key]: newValue
        })
        if (selectedCustomFabric?.id) {
            const newData = addNewCustomFabric?.map(data => data?.id === selectedCustomFabric?.id ? { ...data, [key]: newValue } : data)
            setAddNewCustomFabric(newData)
        }
    };

    const closeHandler = () => {
        setShowModal(false)
        setAddFabricToggle(false)
    }

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        const data = cropper?.getData();
        const imgData = cropper?.getCroppedCanvas().toDataURL()
        setUploadImage({
            ...uploadImage,
            [pillowSide]: imgData
        })
        if (selectedCustomFabric?.id) {
            const newData = addNewCustomFabric?.map(data => data?.id === selectedCustomFabric?.id ? { ...data, imgData } : data)
            setAddNewCustomFabric(newData)
        }
    };

    const onCrop1 = async () => {
        const verticalVal = customBandValue?.Vertical// document.getElementById('Vertical Size')?.value
        const cropper = cropperImageRef?.current?.cropper;
        if (cropper) {
            const selectionCropperData = cropper.getData()
            setCropperData(selectionCropperData)
        }
        if (Number(verticalVal)) {
            var inch = verticalVal
            const finalinch = inch * 156.25
            // const selectionCropperData = cropper.getData()
            const imgData = cropper?.getCroppedCanvas().toDataURL()
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = 2048;
            canvas.height = 2048;
            var canvasWidth = canvas.width;
            var canvasHeight = canvas.height;
            const final_img = imgData
            var image = new Image();
            image.src = final_img;
            await image.decode();
            const { height, width } = image;

            // Set the fixed height for the image
            var imageHeight = finalinch;
            var aspectRatio = width / height;

            // Calculate the new width based on the fixed height
            var imageWidth = imageHeight * aspectRatio;

            // Clear the canvas
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            // Draw the repeating images horizontally
            var xPos = 0;
            while (xPos < canvasWidth) {
                ctx.drawImage(image, xPos, (canvasHeight - imageHeight) / 2, imageWidth, imageHeight);
                xPos += imageWidth;
            }

            var imgData1 = canvas.toDataURL("image/png");
            setAddBandFinalFabricBefore(imgData1)
            // Create a download link
            // var a = document.createElement("a");
            // a.href = imgData1;
            // a.download = "transparent_canvas.png";

            // // Trigger a click event on the download link to initiate the download
            // a.click();


        }
    };
    const pillowTabData = () => {
        const sizeName = currentCustomization.size.name;
        const loaderClassName = " "
        switch (tab) {
            case "TAB_1":
                return (
                    <div className="p-4">
                        <div className="border-bottom mb-4 ">
                            <p className="text-color font-17">SIZE</p>
                            <div className="d-flex align-items-center pb-3">
                                <div className="small-box ">
                                    <img src={currentCustomization.size.image} alt="" />
                                </div>
                                <p
                                    style={{ marginLeft: "10px", marginTop: "20px" }}
                                    className="font-14"
                                >
                                    {currentCustomization.size.setSize}
                                </p>
                            </div>
                        </div>
                        <div className="sizes-wrapper sizes-desktop mobile-view  d-lg-block">
                            <div className="d-flex sizes-mobile align-items-center flex-wrap" id="myDIV">
                                {pillowAllSize.map((pillow) => {
                                    return (
                                        <div key={pillow.image} className={`swatch-box  me-3 position-relative mb-3 ${pillow.setSize === currentCustomization.size.setSize ? 'active' : ''}`} onClick={() => {
                                            setSelectedProduct(selectedProduct);
                                            setCurrentCustomization({
                                                ...currentCustomization,
                                                size: pillow,
                                            });
                                            const allBandName = {
                                                'Inset Band': 'InsetBand',
                                                'Center': 'SingleBand',
                                                'Vertical': 'DoubleBand',
                                                'No': 'No',
                                            }
                                            const borderName = currentCustomization.border.name === '4 Side' ? '4Side' : currentCustomization.border.name === '2 Side' ? '2Side' : currentCustomization.border.name
                                            const bandName = allBandName[currentCustomization.band.name]

                                            let src
                                            if (bandName !== "No" || borderName !== "No") {
                                                if (borderName !== "No" && bandName !== "No") {
                                                    src = models['size'][pillow.name]?.border[borderName][bandName]
                                                } else if (borderName !== "No") {
                                                    src = models['size'][pillow.name]?.border[borderName].id
                                                } else if (bandName !== "No") {
                                                    src = models['size'][pillow.name]?.[bandName]
                                                }
                                            } else {
                                                src = models['size'][pillow.name].id
                                            }
                                            const modelViewer = document.querySelector("#model-viewer");
                                            if (modelViewer) {
                                                modelViewer.cameraOrbit = '0deg 75deg 90%'
                                                modelViewer.fieldOfView = "10";
                                                modelViewer.minFieldOfView='30deg';
                                                modelViewer.maxFieldOfView='30deg';
                                                const degree = sizeToDegree[pillow.setSize] || "30deg";
                                                modelViewer.minFieldOfView=degree;
                                                modelViewer.maxFieldOfView=degree;
                                            }
                                            setModelSrc({
                                                ...modelSrc,
                                                categoryName: "pillow",
                                                src
                                            })
                                            setFabricsAtomState({ ...fabricAtomState, currentPart: [] })
                                        }}>
                                            <img src={pillow.image} alt="" className="size-26" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div >
                );
            case "TAB_2":
                const uploadImageName = uploadImage?.[pillowSide]
                // const sizeValue = inputSetValue?.[pillowSide]
                return (
                    <>
                        {addFabricToggle ? <div className="p-4">
                            <div>
                                <div className={` me-3 position-relative mb-3 d-flex justify-content-end `} >
                                    <div className="cursor-pointer " onClick={() => closeHandler()} style={{ color: 'black' }}>X</div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-12 col-md-6 col-lg-6">
                                        <p className='mb-halfrem color-gray'>Horizontal Repeat</p>
                                        <DebounceInput
                                            type="number"
                                            className={`search-control maxw-100 w-100 `}
                                            placeholder={"Horizontal Repeat"}
                                            autoComplete="off"
                                            value={selectedCustomFabric?.Horizontal}
                                            debounceTimeout={500}
                                            autoFocus={true}
                                            onChange={(e) => onChange(Number(e.target.value), 'Horizontal')}
                                        />
                                    </div>
                                    <div className="col-6 col-sm-12 col-md-6 col-lg-6">
                                        <p className='mb-halfrem color-gray'>Vertical Repeat</p>
                                        <DebounceInput
                                            type="number"
                                            className={`search-control maxw-100 w-100 `}
                                            placeholder={"Vertical Repeat"}
                                            autoComplete="off"
                                            value={selectedCustomFabric?.Vertical}
                                            debounceTimeout={500}
                                            autoFocus={true}
                                            onChange={(e) => onChange(Number(e.target.value), 'Vertical')}
                                        />
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <p className='mb-0 color-gray'>Scale</p>
                                    <Form.Group as={Row} className='w-100 align-items-center m-0'>
                                        <Col xs="9" className="pl-0">
                                            <Slider
                                                min={0}
                                                max={2}
                                                defaultValue={1}
                                                onChange={(val) => {
                                                    setInputSetValue({
                                                        ...inputSetValue,
                                                        [pillowSide]: {
                                                            ...inputSetValue[pillowSide],
                                                            Scale: val
                                                        }
                                                    })
                                                    setSelectedCustomFabricAtom({
                                                        ...selectedCustomFabric,
                                                        Scale: val
                                                    })
                                                }}
                                                value={selectedCustomFabric.Scale}
                                                step={0.1}
                                            />
                                        </Col>
                                        <Col xs="3" className="p-0">
                                            <DebounceInput
                                                type="number"
                                                min={0}
                                                max={2}
                                                className={`search-control w-100`}
                                                placeholder={"Scale"}
                                                defaultValue={1}
                                                autoComplete="off"
                                                value={inputSetValue?.[pillowSide]?.Scale}
                                                debounceTimeout={500}
                                                onChange={(e) => {
                                                    setInputSetValue({
                                                        ...inputSetValue,
                                                        [pillowSide]: {
                                                            ...inputSetValue[pillowSide],
                                                            Scale: Number(e.target.value)
                                                        }
                                                    })
                                                    setSelectedCustomFabricAtom({
                                                        ...selectedCustomFabric,
                                                        Scale: Number(e.target.value)
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className='mt-3'>
                                    <div className="d-flex justify-content-between">
                                        <p>Preview</p>
                                        <p className="cursor-pointer"
                                            style={{ color: '#3ddbd4' }} onClick={() => {
                                                setShowModal(true)
                                                setCanvasToggleRender([])
                                                setAspectRation(inputSetValue[pillowSide]?.aspectRation)
                                            }}>Edit</p>
                                    </div>
                                    <div>
                                        <div>
                                            <div>
                                                <Cropper
                                                    ref={cropperRef}
                                                    style={{ height: '100%', width: "100%" }}
                                                    zoomTo={0}
                                                    zoomable={false}
                                                    initialAspectRatio={1}
                                                    preview=".img-preview"
                                                    src={cropData}
                                                    viewMode={1}
                                                    minCropBoxHeight={10}
                                                    minCropBoxWidth={10}
                                                    background={false}
                                                    responsive={true}
                                                    //  center={false}
                                                    // autoCrop={false}
                                                    // autoCropArea={1}
                                                    autoCropArea={0.5}
                                                    checkOrientation={false}
                                                    guides={true}
                                                    crop={onCrop}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div style={{ height: 500, width: 500, overflow: 'scroll' }} className="image-container">
                                        <canvas id="edit_image"></canvas>
                                        <img id="edit_image1" className="image cursor-grab" src={blackImage[`${currentCustomization?.size?.name}${pillowSide}`]} alt="Image" />
                                    </div> */}
                                </div>

                                <Form.Group as={Row} className='mt-5'>
                                    <Col xs="6">
                                        <Button className=" w-100 w-min-126px" size="lg" variant="primary" type="submit"
                                            onClick={() => closeHandler()}
                                        >
                                            Apply
                                        </Button>
                                    </Col>
                                    <Col xs='6'>
                                        <Button className=" w-100 w-min-126px" style={{
                                            border: " 1px solid black",
                                            backgroundColor: "white"
                                        }} size="lg" variant="light" type="submit" onClick={() => closeHandler()}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div> : <>
                            {!uploadFabricModel && <div className="p-4">
                                <div className="border-bottom mb-4 w-83 mobile-none">
                                    <p className="text-color font-17">FABRIC</p>
                                    <div className="d-flex align-items-center pb-3 w-100">
                                        <div className="small-box ">
                                            {fabricAtomState.partWiseFabric[pillowSide]?.finishThumb ?
                                                <img src={GET_URL(fabricAtomState.partWiseFabric[pillowSide]?.finishThumb)} alt="" />
                                                :
                                                uploadImageName ? <img src={uploadImageName} style={{ height: '100%' }} alt="" /> : <img src={'defalutImage.jpg'} alt="" />
                                            }
                                        </div>
                                        <div className="w-100 ms-2 mb-0">
                                            {category === "pillow" && <p className="font-14 text-gray mb-0">{pillowSide} Side</p>}
                                            {uploadImageName ?
                                                <div className="d-flex justify-content-between">
                                                    <p className="font-14 text-black mb-0">{inputSetValue[pillowSide]?.name || 'Custom Fabric'}</p>
                                                    <div className="d-flex">
                                                        <div className="font-14 mb-0 cursor-pointer"
                                                            style={{ marginLeft: 10, color: '#3ddbd4' }}
                                                            onClick={() => {
                                                                setAddFabricToggle(true)
                                                                setCanvasToggleRender([])
                                                                setSelectedCustomFabricAtom(inputSetValue?.[pillowSide])
                                                            }}>
                                                            Edit Fabric
                                                        </div>
                                                    </div>
                                                </div>
                                                : <p className="font-14 text-black mb-0">
                                                    {uploadImageName ? inputSetValue[pillowSide]?.name || 'Custom Fabric' : fabricAtomState.partWiseFabric[pillowSide]?.name || 'Plain Fabric'}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-wrap">
                                    <div
                                        onClick={() => {
                                            setPillowSide("Front")
                                            setFabricsAtomState({ ...fabricAtomState, currentPart: ["Front"] })
                                            setCanvasToggleRender([])
                                            if (inputSetValue['Front']) {
                                                setSelectedCustomFabricAtom({ ...inputSetValue['Front'] })
                                            }
                                            const modelViewer = document.querySelector("#model-viewer");
                                            if (modelViewer) {
                                                modelViewer.cameraOrbit = '0deg 75deg 105%';
                                            }
                                        }}
                                        className={`side-box ${pillowSide === "Front" ? 'active' : ''} me-3 position-relative`}>
                                        <img src="front.png" alt="" className="side-img" />
                                        <p className="font-14 text-black text-center mb-0">
                                            Front Side
                                        </p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setPillowSide("Back");
                                            setFabricsAtomState({ ...fabricAtomState, currentPart: ["Back"] })
                                            setCanvasToggleRender([])
                                            if (inputSetValue['Back']) {
                                                setSelectedCustomFabricAtom({ ...inputSetValue['Back'] })
                                            }
                                            const modelViewer = document.querySelector("#model-viewer");
                                            if (modelViewer) {
                                                modelViewer.cameraOrbit = '180deg 75deg 105%';
                                            }
                                        }}
                                        className={`side-box ${pillowSide === "Back" ? 'active' : ''} me-3 position-relative`}>
                                        <img src="back.png" alt="" className="side-img" />
                                        <p
                                            className="font-14 text-center mb-0"
                                            style={{ color: "grey" }}
                                        >
                                            Back Side
                                        </p>
                                    </div>
                                </div>
                            </div>
                            }
                            <FabricSelection
                                pillowSide={pillowSide}
                                materialCategory={material.pillowFabric}
                                subMaterialCategory={subMaterial.pillowFabric}
                                header="FABRIC"
                                setFabric={setFabricImg}
                            />
                        </>
                        }
                    </>
                )
            case "TAB_3":
                const allBandName = {
                    'Inset Band': 'InsetBand',
                    'Center': 'SingleBand',
                    'Vertical': 'DoubleBand',
                    'No': 'No',
                }
                const borderName1 = currentCustomization.border.name === '4 Side' ? '4Side' : currentCustomization.border.name === '2 Side' ? '2Side' : currentCustomization.border.name;
                const bandName = allBandName[currentCustomization.band.name]
                const selectedEdge = fabricAtomState.partWiseFabric[fabricAtomState.currentPart[0]]
                const borderModal = models['size'][currentCustomization.size.name]['border']
                const Flange1Band = bandName === 'No' ? borderModal['Flange 1"'].id : borderModal['Flange 1"'][bandName]
                const Flange2Band = bandName === 'No' ? borderModal['Flange 2"'].id : borderModal['Flange 2"'][bandName]
                const WeltBand = bandName === 'No' ? borderModal['Welt'].id : borderModal['Welt'][bandName]
                const Side4Band = bandName === 'No' ? borderModal['4Side'].id : borderModal['4Side'][bandName]
                const Side2Band = bandName === 'No' ? borderModal['2Side'].id : borderModal['2Side'][bandName]
                const NoBand4 = bandName === 'No' ? models['size'][currentCustomization.size.name].id : models['size'][currentCustomization.size.name][bandName]
                // const NoBand4 = borderName1 === 'No' ? models['size'][currentCustomization.size.name].id : models['size'][currentCustomization.size.name][bandName]
                const selectedEdgeImage = borderName1 === 'No' ? currentCustomization.border.image : selectedEdge?.finish ? GET_URL(selectedEdge?.finish) : 'pillows-no.png'

                return (
                    <div className="p-4" >
                        <div className="border-bottom mb-4 ">
                            <p className="text-color font-17">EDGE</p>
                            <div className="d-flex align-items-center pb-3">
                                <div className="small-box ">
                                    {selectedEdgeImage && <img src={selectedEdgeImage} alt="" className="p-1" />}
                                </div>
                                <div className=" ms-2 mb-0">
                                    <p className="font-14 text-gray mb-0">{
                                        currentCustomization.border.name}</p>
                                    {borderName1 !== 'No' && <p className="font-14 text-black mb-0">{selectedEdge?.name}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-4">
                            <div className={`side-box me-3 position-relative mb-3 
                            ${currentCustomization.border.name === 'Flange 1"' ? 'active' : ''}
                            ${loaderClassName}`}
                                onClick={() => {
                                    setLoader(true)
                                    setSelectedProduct(selectedProduct);
                                    setCurrentCustomization({
                                        ...currentCustomization,
                                        border: {
                                            name: `Flange 1"`,
                                            image: "pillows-1.png",
                                        },
                                    });
                                    setModelSrc({
                                        ...modelSrc,
                                        src: Flange1Band
                                    });
                                    setFabricsAtomState({ ...fabricAtomState, currentPart: ['Flange'] })

                                }}>
                                <img src="pillows-1.png" alt="" className="side-img" />
                                <p className="font-14 text-black text-center mb-0">Flange 1"</p>
                            </div>
                            <div
                                className={`side-box me-3 position-relative mb-3 
                                ${currentCustomization.border.name === 'Flange 2"' ? 'active' : ''} 
                                ${loaderClassName} `}
                                onClick={() => {
                                    setLoader(true)
                                    setSelectedProduct(selectedProduct);
                                    setCurrentCustomization({
                                        ...currentCustomization,
                                        border: {
                                            name: `Flange 2"`,
                                            image: "pillows-2.png",
                                        },
                                    });
                                    setModelSrc({
                                        ...modelSrc,
                                        categoryName: "pillow",
                                        src: Flange2Band
                                    });
                                    setFabricsAtomState({ ...fabricAtomState, currentPart: ['Flange'] })
                                }}>
                                <img src="pillows-2.png" alt="" className="side-img" />
                                <p
                                    className="font-14 text-center mb-0"
                                    style={{ color: "grey" }}
                                >
                                    Flange 2"
                                </p>
                            </div>
                            <div
                                className={`side-box me-3 position-relative mb-3 ${currentCustomization.border.name === 'Welt' ? 'active' : ''} 
                                ${loaderClassName}`}
                                onClick={() => {
                                    setLoader(true)
                                    setSelectedProduct(selectedProduct);
                                    setCurrentCustomization({
                                        ...currentCustomization,
                                        border: {
                                            name: "Welt",
                                            image: "pillows-3.png",
                                        },
                                    });

                                    setModelSrc({
                                        ...modelSrc,
                                        categoryName: "pillow",
                                        src: WeltBand
                                    });
                                    setFabricsAtomState({ ...fabricAtomState, currentPart: ['Welt'] })
                                }}>
                                <img src="pillows-3.png" alt="" className="side-img" />
                                <p
                                    className="font-14 text-center mb-0"
                                    style={{ color: "grey" }}
                                >
                                    Welt
                                </p>
                            </div>
                            <div className={`side-box me-3 position-relative mb-3 ${currentCustomization.border.name === '4 Side' ? 'active ' : ''} 
                            ${loaderClassName}`}
                                onClick={() => {
                                    setLoader(true)
                                    setSelectedProduct(selectedProduct);
                                    setCurrentCustomization({
                                        ...currentCustomization,
                                        border: {
                                            name: "4 Side",
                                            image: "pillows-1610.png",
                                        },
                                    });
                                    setModelSrc({
                                        ...modelSrc,
                                        categoryName: "pillow",
                                        src: Side4Band
                                    });
                                    // setPartsName(parts => {
                                    //     let newParts = parts.filter((part) => part !== 'Flange')
                                    //     return [...newParts, 'Welt']
                                    // })
                                    // setPartsName(['Fringe'])
                                    setFabricsAtomState({ ...fabricAtomState, currentPart: ['Fringe'] })
                                }}
                            >
                                <img src="pillows-1610.png" alt="" className="side-img" />
                                <p className="font-14 text-black text-center mb-0">4 Side</p>
                            </div>
                            <div className={`side-box me-3 position-relative mb-3 ${currentCustomization.border.name === '2 Side' ? 'active ' : ''} 
                            ${loaderClassName}`}
                                onClick={() => {
                                    setLoader(true)
                                    setSelectedProduct(selectedProduct);
                                    setCurrentCustomization({
                                        ...currentCustomization,
                                        border: {
                                            name: "2 Side",
                                            image: "pillows-790a996c952625d70d3f5c87519096e8_edge-removebg-preview.png",
                                        },
                                    });
                                    setModelSrc({
                                        ...modelSrc,
                                        categoryName: "pillow",
                                        src: Side2Band
                                    });
                                    // setPartsName(['Fringe'])
                                    setFabricsAtomState({ ...fabricAtomState, currentPart: ['Fringe'] })
                                }}
                            >
                                <img
                                    src="pillows-790a996c952625d70d3f5c87519096e8_edge-removebg-preview.png"
                                    alt=""
                                    className="side-img"
                                />
                                <p className="font-14  text-center mb-0 text-gray">2 Side</p>
                            </div>
                            <div
                                className={`side-box me-3 position-relative mb-3 ${currentCustomization.border.name === 'No' ? 'active ' : ''} 
                                    ${loaderClassName} `}
                                onClick={() => {
                                    setLoader(true)
                                    setCurrentCustomization({
                                        ...currentCustomization,
                                        border: {
                                            name: "No",
                                            image: "pillows-no.png",
                                        },
                                    });
                                    setModelSrc({
                                        ...modelSrc,
                                        categoryName: "pillow",
                                        src: NoBand4
                                    });
                                    setFabricsAtomState({ ...fabricAtomState, currentPart: ['Flange', 'Flange_2_inch', "Welt", 'Fringe'] })
                                }}
                            >
                                <img src="pillows-no.png" alt="" className="side-img" />
                                <p className="font-14 text-center mb-0 text-gray">NO</p>
                            </div>
                        </div>

                        <FabricSelection
                            pillowSide={pillowSide}
                            key="border"
                            materialCategory={material.pillowFabric}
                            subMaterialCategory={currentCustomization?.border?.name === 'No' ? '' : ['4 Side', '2 Side']?.includes(currentCustomization?.border?.name) ? subMaterial.pillow2_4Side : subMaterial.pillowFabric}
                            header="BORDER"
                            setFabric={setFabricImg}
                        />
                    </div>
                );
            case "TAB_4":
                return (
                    <div className="p-4" >
                        <div className="border-bottom mb-4 ">
                            <p className="text-color font-17">TRIM</p>
                            <div className="d-flex align-items-center pb-3">
                                <div className="small-box ">
                                    <img src="border-1.png" alt="" className="p-1" />
                                </div>
                                <div className=" ms-2 mb-0">
                                    <p className="font-14 text-gray mb-0">4 Side</p>
                                    <p className="font-14 text-black mb-0">Purple</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-4 ">
                            <div className="side-box me-3 active position-relative mb-3">
                                <img src="pillows-1610.png" alt="" className="side-img" />
                                <p className="font-14 text-black text-center mb-0">4 Side</p>
                            </div>
                            <div className="side-box me-3 position-relative mb-3">
                                <img
                                    src="pillows-790a996c952625d70d3f5c87519096e8_edge-removebg-preview.png"
                                    alt=""
                                    className="side-img"
                                />
                                <p className="font-14  text-center mb-0 text-gray">2 Side</p>
                            </div>
                            <div className="side-box me-3 position-relative mb-3">
                                <img
                                    src="pillows-Piper_2353_4533e523_95b8_4e83_9fbc_7910275a7d59_576x576_edge-removebg-preview.png"
                                    alt=""
                                    className="side-img"
                                />
                                <p className="font-14  text-center mb-0 text-gray">Center</p>
                            </div>
                            <div className="side-box me-3 position-relative mb-3">
                                <img
                                    src="pillows-TrackTrim_1024x1024_edge-removebg-preview.png"
                                    alt=""
                                    className="side-img"
                                />
                                <p className="font-14  text-center mb-0 text-gray">Vertical</p>
                            </div>
                        </div>
                        <div className="control-wrapper">
                            <div className="d-flex align-items-center ">
                                <div className="form-group position-relative me-3 mw-100 w-100">
                                    <input
                                        type="type"
                                        placeholder="Search Paints"
                                        className="search-control mw-88"
                                    />
                                    <img src="search.png" className="search-icon" />
                                </div>
                            </div>
                            <div className="item-choose w-100 pt-3">
                                <div className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 w-100" >
                                    <div className="item-choose me-3 mb-3">
                                        <img src="grey.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="creemy.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="purple.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="sky.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="blue.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="brown.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="purple-1.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="yellow.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Yellow
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="grey.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="creemy.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="purple.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="sky.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="blue.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="brown.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "TAB_5":
                const nameToNameMap = {
                    "Inset Band": "Front_Inset_Band",
                    "Center": currentCustomization?.size?.name === '20x20' ? "Front_CenterBand" : "Front_Vertical_Band",
                    "Vertical": "Front_Double_Band",
                }
                const allBandName1 = {
                    'Inset Band': 'InsetBand',
                    'Center': 'SingleBand',
                    'Vertical': 'DoubleBand',
                    'No': 'No',
                }
                const bandName1 = allBandName1[currentCustomization.band.name]
                const selectedBand = fabricAtomState?.partWiseFabric[nameToNameMap?.[currentCustomization?.band?.name]] || { name: '', finish: '' };
                const borderName = currentCustomization.border.name === '4 Side' ? '4Side' : currentCustomization.border.name === '2 Side' ? '2Side' : currentCustomization.border.name;
                const modalSizeName = models['size'][sizeName];
                const borderData = modalSizeName['border']?.[borderName] || {}; // Use optional chaining and nullish coalescing to handle potential undefined values
                const InsetBandSrc = borderName === "No" ? modalSizeName['InsetBand'] : borderData['InsetBand'] || '';
                const SingleBandSrc = borderName === "No" ? modalSizeName['SingleBand'] : borderData['SingleBand'] || '';
                const DoubleBand = borderName === "No" ? modalSizeName['DoubleBand'] : borderData['DoubleBand'] || '';
                const NoBand5 = borderName === 'No' && bandName1 === 'No' ? modalSizeName.id : borderName === 'No' ? modalSizeName.id : modalSizeName['border'][borderName].id
                const base64Image = selectedBand?.finish?.search('data:image') !== -1 ? true : false
                const selectedImage = bandName1 === "No" ? currentCustomization.band.image : base64Image ? selectedBand?.finish : selectedBand?.finish ? GET_URL(selectedBand?.finish) : 'pillows-no.png' // 

                return (
                    <>
                        {addFabricToggle ? <div className="p-4">
                            <div>
                                <div className={` me-3 position-relative mb-3 d-flex justify-content-end `} >
                                    <div className="cursor-pointer " onClick={() => closeHandler()} style={{ color: 'black' }}>X</div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-12 col-md-6 col-lg-6">
                                        <p className='mb-halfrem color-gray'>Vertical Size (Inch)</p>
                                        <DebounceInput
                                            type="number"
                                            className={`search-control maxw-100 w-100 `}
                                            placeholder={"Vertical Size"}
                                            autoComplete="off"
                                            value={customBandValue?.Vertical}
                                            debounceTimeout={500}
                                            autoFocus={true}
                                            onChange={(e) => {
                                                setCustomBandValue({
                                                    ...customBandValue,
                                                    'Vertical': e.target.value
                                                })
                                                setTimeout(() => {
                                                    onCrop1()
                                                }, 1000)
                                            }}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <p className='m-0 p-0 color-gray'>Genarate normal</p>
                                            <input type="checkbox" className='m-0 p-0'
                                                style={{ width: 16 }}
                                                checked={customBandValue?.show}
                                                onChange={(e) => {
                                                    setCustomBandValue({
                                                        ...customBandValue,
                                                        'show': e.target.checked
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-3'>
                                    <div className="d-flex justify-content-between">
                                        <p>Preview</p>
                                        {/* <p className="cursor-pointer"
                                            style={{ color: '#3ddbd4' }} onClick={() => {
                                                setShowModal(true)
                                                setCanvasToggleRender([])
                                                setAspectRation(inputSetValue[pillowSide]?.aspectRation)
                                            }}>Edit</p> */}
                                    </div>
                                    <div>
                                        <div>
                                            <div>
                                                <Cropper
                                                    ref={cropperImageRef}
                                                    style={{ height: '100%', width: "100%" }}
                                                    zoomTo={0}
                                                    zoomable={false}
                                                    initialAspectRatio={1}
                                                    preview=".img-preview"
                                                    src={addBandFabricBefore}
                                                    viewMode={1}
                                                    minCropBoxHeight={10}
                                                    minCropBoxWidth={10}
                                                    background={false}
                                                    responsive={true}
                                                    autoCropArea={0.5}
                                                    checkOrientation={false}
                                                    guides={true}
                                                    cropend={onCrop1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Form.Group as={Row} className='mt-5'>
                                    <Col xs="6">
                                        <Button className=" w-100 w-min-126px" size="lg" variant="primary" type="submit"
                                            onClick={() => closeHandler()}
                                        >
                                            Apply
                                        </Button>
                                    </Col>
                                    <Col xs='6'>
                                        <Button className=" w-100 w-min-126px" style={{
                                            border: " 1px solid black",
                                            backgroundColor: "white"
                                        }} size="lg" variant="light" type="submit" onClick={() => closeHandler()}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div> : <>
                            {!uploadFabricModel && <div className="p-4" >
                                <div className="border-bottom mb-3 ">
                                    <p className="text-color font-17 pb-3 mb-0">BAND</p>
                                    <div className="d-flex align-items-center pb-3">
                                        <div className="small-box ">
                                            <img src={selectedImage} alt="" className="p-1" />
                                        </div>
                                        <div className="w-100 ms-2 mb-0">
                                            <p className="font-14 text-gray mb-0">{currentCustomization.band.name}</p>
                                            {addBandFabricBefore ?
                                                <div className="d-flex justify-content-between">
                                                    <p className="font-14 text-black mb-0">{selectedBand?.name || 'Custom Band'}</p>
                                                    <div className="d-flex">
                                                        <div className="font-14 mb-0 cursor-pointer"
                                                            style={{ marginLeft: 10, color: '#3ddbd4' }}
                                                            onClick={() => {
                                                                setSelectedFabricBand('BORDER')
                                                                setShowBandModal(true)
                                                                setUploadFabricModel(false)
                                                                if (selectedBand?.cropperData) {
                                                                    setCropperData(selectedBand?.cropperData)
                                                                }
                                                                setCustomBandValue(selectedBand)
                                                            }}>
                                                            Edit Band
                                                        </div>
                                                    </div>
                                                </div>
                                                : <p className="font-14 text-black mb-0">{selectedBand?.name}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`d-flex pb-3 mb-3 border-bottom ${loaderClassName}`}>
                                    <div onClick={() => {
                                        const currentPart = fabricAtomState.currentPart.filter((part) => !['Back_Inset_Band', 'Back_Vertical_Band', 'Back_CenterBand', 'Back_Double_Band'].includes(part))
                                        const { Back_Inset_Band, Back_Vertical_Band, Back_CenterBand, Back_Double_Band, ...restPart } = fabricAtomState.partWiseFabric
                                        setFabricsAtomState({
                                            currentPart,
                                            partWiseFabric: restPart
                                        })
                                        setCurrentCustomization({
                                            ...currentCustomization,
                                            band: {
                                                ...currentCustomization.band,
                                                type: 'single'
                                            },
                                        });
                                    }}
                                        className={`side-box ${currentCustomization.band.type === "single" ? 'active' : ''} me-3 position-relative`}>
                                        <p className="font-14 text-black text-center mb-0">
                                            Single Side
                                        </p>
                                    </div>
                                    <div onClick={() => {
                                        const keys = Object.keys(fabricAtomState.partWiseFabric)
                                        let part = fabricAtomState.partWiseFabric
                                        let currentParts = fabricAtomState.currentPart
                                        if (keys.includes('Front_Inset_Band')) {
                                            part = { ...part, Back_Inset_Band: part.Front_Inset_Band }
                                            currentParts = [...currentParts, 'Back_Inset_Band']
                                        }
                                        if (keys.includes('Front_Vertical_Band')) {
                                            part = { ...part, Back_Vertical_Band: part.Front_Vertical_Band }
                                            currentParts = [...currentParts, 'Back_Vertical_Band']
                                        }
                                        if (keys.includes('Front_CenterBand')) {
                                            part = { ...part, Back_CenterBand: part.Front_CenterBand }
                                            currentParts = [...currentParts, 'Back_CenterBand']
                                        }
                                        if (keys.includes('Front_Double_Band')) {
                                            part = { ...part, Back_Double_Band: part.Front_Double_Band }
                                            currentParts = [...currentParts, 'Back_Double_Band']
                                        }
                                        setFabricsAtomState({
                                            ...fabricAtomState,
                                            currentPart: currentParts,
                                            partWiseFabric: part
                                        })
                                        setCurrentCustomization({
                                            ...currentCustomization,
                                            band: {
                                                ...currentCustomization.band,
                                                type: 'double'
                                            },
                                        });
                                    }}
                                        className={`side-box ${currentCustomization.band.type === 'double' ? 'active' : ''} me-3 position-relative`}>
                                        <p className="font-14 text-black text-center mb-0">
                                            Double Side
                                        </p>
                                    </div>
                                    <div
                                        className={`side-box ${currentCustomization.band.type === "No" ? 'active' : ''} me-3 position-relative`}
                                        onClick={() => {
                                            setLoader(true)
                                            setCurrentCustomization({
                                                ...currentCustomization,
                                                band: {
                                                    name: "No",
                                                    image: "pillows-no.png",
                                                    type: 'No'
                                                }
                                            });
                                            setModelSrc({
                                                ...modelSrc,
                                                categoryName: "Pillow",
                                                src: NoBand5
                                            });
                                            setFabricsAtomState({ ...fabricAtomState, currentPart: [] })
                                        }}
                                    >
                                        <p className="font-14 text-black text-center mb-0">
                                            No
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap mb-4 h-100 ">
                                    <div
                                        className={`side-box me-3 position-relative ${currentCustomization.band.name === "Inset Band" ? "active" : ""} ${loaderClassName}`}
                                        onClick={() => {
                                            setLoader(true)
                                            setSelectedProduct(selectedProduct);
                                            if (currentCustomization.band.type === 'No') {
                                                setCurrentCustomization({
                                                    ...currentCustomization,
                                                    band: {
                                                        type: 'single',
                                                        name: "Inset Band",
                                                        image: "pillows-yes.png"
                                                    }
                                                });
                                            } else {
                                               
                                                setCurrentCustomization({
                                                    ...currentCustomization,
                                                    band: {
                                                        ...currentCustomization.band,
                                                        name: "Inset Band",
                                                        image: "pillows-yes.png"
                                                    }
                                                });
                                            }
                                            if (currentCustomization.band.type === 'double') {
                                                setFabricsAtomState({ ...fabricAtomState, currentPart: ['Front_Inset_Band', 'Back_Inset_Band','Front_Vertical_Band', 'Back_CenterBand', 'Front_CenterBand', 'Back_Vertical_Band','Front_Double_Band', 'Back_Double_Band'] })
                                            } else {
                                                setFabricsAtomState({ ...fabricAtomState, currentPart: ['Front_Inset_Band','Front_Vertical_Band', 'Front_CenterBand','Front_Double_Band'] })
                                            }

                                            setModelSrc({
                                                ...modelSrc,
                                                categoryName: 'Pillow',
                                                src: InsetBandSrc
                                            });
                                            const modelViewer = document.querySelector("#model-viewer");
                                            if (modelViewer) {
                                                modelViewer.cameraOrbit = '0deg 75deg 105%';
                                            }
                                            
                                            if (fabricAtomState?.partWiseFabric?.Front_Inset_Band?.customBand) {
                                                setSelectedBand(fabricAtomState?.partWiseFabric?.Front_Inset_Band)
                                            } else {
                                                setSelectedBand({ id: '' })
                                            }
                                        }}
                                    >
                                        <div className="pillow-band-type">
                                            <img src="pillows-yes.png" alt="" className="side-img" />
                                        </div>
                                        <p className="font-14 text-black text-center mb-0">Inset</p>
                                    </div>
                                    <div
                                        className={`side-box me-3 position-relative ${currentCustomization.band.name === "Center" ? "active" : ""} ${loaderClassName}`}
                                        onClick={() => {
                                            setLoader(true)
                                            setSelectedProduct(selectedProduct);
                                            if (currentCustomization.band.type === 'No') {
                                                setCurrentCustomization({
                                                    ...currentCustomization,
                                                    band: {
                                                        type: 'single',
                                                        name: "Center",
                                                        image: "pillows/Piper_2353_4533e523_95b8_4e83_9fbc_7910275a7d59_576x576_edge-removebg-preview.png"
                                                    }
                                                });
                                            } else {
                                                setCurrentCustomization({
                                                    ...currentCustomization,
                                                    band: {
                                                        ...currentCustomization.band,
                                                        name: "Center",
                                                        image: "pillows/Piper_2353_4533e523_95b8_4e83_9fbc_7910275a7d59_576x576_edge-removebg-preview.png"
                                                    }
                                                });
                                            }
                                            if (['single', 'No']?.includes(currentCustomization.band.type)) {
                                                setFabricsAtomState({ ...fabricAtomState, currentPart: ['Front_Inset_Band','Front_Vertical_Band', 'Front_CenterBand','Front_Double_Band'] })
                                            } else {
                                                setFabricsAtomState({ ...fabricAtomState, currentPart: ['Front_Inset_Band', 'Back_Inset_Band','Front_Vertical_Band', 'Back_CenterBand', 'Front_CenterBand', 'Back_Vertical_Band','Front_Double_Band', 'Back_Double_Band'] })
                                            }
                                            setModelSrc({
                                                ...modelSrc,
                                                categoryName: 'Pillow',
                                                src: SingleBandSrc
                                            });
                                            console.log("currentCustomization",fabricAtomState)
                                            if (fabricAtomState?.partWiseFabric?.Front_CenterBand?.customBand || fabricAtomState?.partWiseFabric?.Front_Vertical_Band?.customBand) {
                                                setSelectedBand(fabricAtomState?.partWiseFabric?.Front_CenterBand || fabricAtomState?.partWiseFabric?.Front_Vertical_Band)
                                            } else {
                                                setSelectedBand({ id: '' })
                                            }
                                        }}
                                    >
                                        <div className="pillow-band-type">

                                            <img
                                                src="pillows/Piper_2353_4533e523_95b8_4e83_9fbc_7910275a7d59_576x576_edge-removebg-preview.png"
                                                alt=""
                                                className="side-img"
                                            />
                                        </div>
                                        <p className="font-14  text-center mb-0 text-gray">Center</p>
                                    </div>
                                    <div
                                        className={`side-box me-3 position-relative band-side-box ${currentCustomization.band.name === "Vertical" ? "active" : ""} ${loaderClassName}`}
                                        onClick={() => {
                                            setLoader(true)
                                            setSelectedProduct(selectedProduct);
                                            if (currentCustomization.band.type === 'No') {
                                                setCurrentCustomization({
                                                    ...currentCustomization,
                                                    band: {
                                                        type: 'single',
                                                        name: "Vertical",
                                                        image: "pillows-TrackTrim_1024x1024_edge-removebg-preview.png"
                                                    }
                                                });
                                            } else {
                                                setCurrentCustomization({
                                                    ...currentCustomization,
                                                    band: {
                                                        ...currentCustomization.band,
                                                        name: "Vertical",
                                                        image: "pillows-TrackTrim_1024x1024_edge-removebg-preview.png"
                                                    }
                                                });
                                            }
                                            if (['single', 'No']?.includes(currentCustomization.band.type)) {
                                                setFabricsAtomState({ ...fabricAtomState, currentPart: ['Front_Inset_Band','Front_Vertical_Band', 'Front_CenterBand','Front_Double_Band'] })
                                            } else {
                                                setFabricsAtomState({ ...fabricAtomState, currentPart: ['Front_Inset_Band', 'Back_Inset_Band','Front_Vertical_Band', 'Back_CenterBand', 'Front_CenterBand', 'Back_Vertical_Band','Front_Double_Band', 'Back_Double_Band'] })
                                            }
                                            setModelSrc({
                                                ...modelSrc,
                                                categoryName: "Pillow",
                                                src: DoubleBand
                                            });
                                            if (fabricAtomState?.partWiseFabric?.Front_Double_Band?.customBand) {
                                                setSelectedBand(fabricAtomState?.partWiseFabric?.Front_Double_Band)
                                            } else {
                                                setSelectedBand({ id: '' })
                                            }
                                        }}
                                    >
                                        <div className="pillow-band-type">

                                            <img
                                                src="pillows-TrackTrim_1024x1024_edge-removebg-preview.png"
                                                alt=""
                                                className="side-img"
                                            />
                                        </div>
                                        <p className="font-14  text-center mb-0 text-gray">Vertical</p>
                                    </div>
                                </div>
                            </div>
                            }
                            <FabricSelection
                                pillowSide={pillowSide}
                                key="inset band"
                                header="BORDER"
                                materialCategory={material.pillowFabric}
                                subMaterialCategory={subMaterial.pillowNo}
                                setFabric={setFabricImg}
                            />
                        </>
                        }
                    </>
                );
            case "TAB_6":
                return <div className="p-4">
                    <div className="border-bottom mb-3 w-83 mobile-none">
                        <p className="text-color font-17">INSERT</p>
                        <div className="d-flex align-items-center pb-3">
                            <div className="small-box ">
                                <img src={currentCustomization?.insertType?.image} style={{ width: '100%', }} alt="" />
                            </div>
                            <div className=" ms-2 mb-0">
                                <p className="font-14 text-black mb-0">
                                    {currentCustomization?.insertType?.name}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                        {insertType?.map((data, index) => {
                            return <div
                                className={`side-box mb-2 position-relative band-side-box ${insertType.length !== (index + 1) ? 'me-3 ' : ''} ${currentCustomization?.insertType?.name === data?.name ? "active" : ""} ${loaderClassName}`}
                                key={index}
                                onClick={() => {
                                    setCurrentCustomization({
                                        ...currentCustomization,
                                        insertType: data,
                                    });
                                }}
                            >
                                <div className="pillow-band-type" style={{ maxWidth: 65 }}>
                                    <img
                                        src={data?.image}
                                        alt=""
                                        className="side-img"
                                        style={{ width: 66 }}
                                    />
                                </div>
                                <p className="font-14  text-center mb-0 text-gray" style={{ width: 117 }}>{data?.name}</p>
                            </div>
                        })}
                    </div>
                </div>
        }
    };
    const vintageTabData = () => {
        const loaderClassName = " "
        if (!["TAB_1", "TAB_2"].includes(tab)) {
            setTab("TAB_1");
        }
        switch (tab) {
            case "TAB_1":
                return (
                    <div className="p-4">
                        <div className={`border-bottom mb-4 w-100`}>
                            <p className="text-color font-17">PAINT</p>
                            <div className="d-flex align-items-center pb-3">
                                <div className="small-box ">
                                    {vintageAtomState?.partWiseFabric?.Base?.finishThumb &&
                                        <img src={GET_URL(vintageAtomState?.partWiseFabric?.Base?.finishThumb)} alt="" />
                                    }
                                </div>
                                <p
                                    style={{ marginLeft: "10px", marginTop: "20px" }}
                                    className="font-14"
                                >
                                    {vintageAtomState?.partWiseFabric?.Base?.name}
                                </p>
                            </div>
                        </div>
                        <div className={`d-flex pb-3 ${loaderClassName}`}>
                            <div onClick={() => {
                                setLoader(true)
                                if (alphaSelect?.type !== 'wax') {
                                    setAlphaSelect({
                                        name: 'Clear Wax',
                                        type: 'wax',
                                        image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Clear%20Wax.png',
                                        index: 1,
                                        exposure: 0.7,
                                        code: "42049056866556"
                                    })
                                }
                                setAlpha("wax")
                                setVintageAtomState({
                                    ...vintageAtomState,
                                    paint: '',
                                    partWiseFabric: {}
                                })
                            }}
                                className={`side-box ${alpha === "wax" ? 'active' : ''} me-3 position-relative`}>
                                <p className="font-14 text-black text-center mb-0">
                                    Joile Chalk Paint
                                </p>
                            </div>
                            <div onClick={() => {
                                if (alphaSelect?.type !== 'glaze') {
                                    setAlphaSelect({ name: 'No Glaze', exposure: 0.7, code: "", image: 'No Glaze.png', type: "glaze", index: 1 })
                                }
                                setAlpha("glaze")
                                setVintageAtomState({
                                    ...vintageAtomState,
                                    paint: '',
                                    partWiseFabric: {}
                                })
                            }}
                                className={`side-box ${alpha === "glaze" ? 'active' : ''} me-3 position-relative`}>
                                <p className="font-14 text-black text-center mb-0">
                                    General Finishes
                                </p>
                            </div>
                        </div>
                        <FabricSelection
                            pillowSide={pillowSide}
                            type='vintage'
                            materialCategory={material.vintagePaint}
                            subMaterialCategory={alpha === 'wax' ? subMaterial.waxVintagePaint : subMaterial.glazeVintagePaint}
                            header="PAINT"
                            setFabric={setFabricImg}
                        />
                        {alpha === "glaze" ? <>
                            <div className="text-color font-17 mb-3 border-bottom py-2">GLAZE FINISH</div>
                            <div className="d-flex w-100 mobile-scroll pt-2">
                                {GlazeFinishAlphaData.map((finish, index) => {
                                    return (
                                        <div className={`cursor-pointer `}
                                            key={finish?.name} onClick={() => setAlphaSelect({ ...finish, type: "glaze", index: index + 1 })} >
                                            <div
                                                className={`${(finish?.name === alphaSelect?.name || (alphaSelect?.type !== "glaze" && index == 0)) ? 'choose-card active' : ''} d-flex align-item-center justify-content-center me-3`}
                                                style={{ height: 75, width: 75 }}
                                            >
                                                <img src={`${finish?.image}`} className="img-fluid" />
                                            </div>
                                            <p className="font-14 text-center text-black mb-0 me-3" style={{ width: 75, height: 42, overflow: "hidden" }}>
                                                {finish?.name}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        </> : <>
                            <div className="text-color font-17 mb-3 border-bottom py-2">WAX FINISH</div>
                            <div className="d-flex  w-75">
                                {WaxFinishAlphaData.map((finish, index) => {
                                    return (
                                        <div className={`cursor-pointer `}
                                            key={finish?.name} onClick={() => setAlphaSelect({ ...finish, type: "wax", index: index + 1 })} >
                                            {/* <div className={`${index === 0 ? 'choose-card active' : ''} me-3`}  > */}
                                            <div className={`${(finish?.name === alphaSelect?.name || (alphaSelect?.type !== "wax" && index == 0)) ? 'choose-card active' : ''} me-3`}  >
                                                <img src={`${finish?.image}`} className="img-fluid mobile-size" />
                                            </div>
                                            <p className="font-14 text-center text-black mb-0 me-3" style={{ width: 75, height: 42 }}>
                                                {finish?.name}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>

                        </>}

                    </div>
                );
            case "TAB_2":
                return (
                    <div className="p-4" >
                        <div className="border-bottom w-100">
                            <p className="text-color font-17">HARDWARE</p>
                            {vintageAtomState.handle && (<div className="d-flex align-items-center pb-3">
                                <div className="small-box ">

                                    <img src={"handle_thumb/" + vintageAtomState.handle.split(" ").join("_") + ".jpg"} alt="" />
                                </div>
                                <div className=" ms-2 mb-0">
                                    <p className="font-14 text-black mb-0 ">{vintageAtomState.handle}</p>
                                </div>
                            </div>)}
                        </div>
                        <div className="control-wrapper ">
                            <div className="item-choose w-100 pt-3 ">
                                <div
                                    className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 w-100"
                                    style={{ height: '100%' }}
                                >
                                    {
                                        Object.values(content["Handle"]).map(item => {
                                            return (
                                                <div
                                                    className={`fabric-box item-choose me-3 mb-3  cursor-pointer  ${vintageAtomState.handle === item.name ? 'active' : ''} ${loaderClassName}`}
                                                    key={item.name}
                                                    onClick={() => {
                                                        // 
                                                        // setLoader(true)
                                                        setVintageAtomState({ ...vintageAtomState, code: item?.code, handle: item.name, image: item.image });
                                                        const THREE_D_MODELS = content.THREE_D_MODELS
                                                        const VINTAGE_FURNITURE = THREE_D_MODELS["VINTAGE_FURNITURE"];
                                                        const src = VINTAGE_FURNITURE[vintageAtomState.name][item.name.split(" ").join("_")].id;
                                                        setModelSrc({
                                                            ...modelSrc,
                                                            categoryName: "vintage",
                                                            src: src
                                                        })
                                                    }}
                                                >
                                                    <img src={item.image} className={` ${vintageAtomState.handle === item.name ? '' : 'border'} img-fluid`} title={item.name} />
                                                    <p className="font-14 text-center text-black mb-0" style={{
                                                        width: 75, height: 42
                                                    }}>
                                                        {item.sortName}
                                                    </p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    }
    const draperyTabData = () => {
        switch (tab) {
            case "TAB_1":
                const { DRAPERY } = content.inspirations;
                const draperyData = DRAPERY['Drapery'].size;
                // const draperySizes:any = content["DRAPERY"][drapryAtomState.name];
                return (
                    <div className="p-4">
                        <div className="border-bottom mb-4 w-100">
                            <p className="text-color font-17">WINDOW SIZE</p>
                            <div className="d-flex align-items-center pb-3">
                                <div className="small-box">
                                    <img src={drapryAtomState.sizeImage} alt="" />
                                </div>
                                <p
                                    style={{ marginLeft: "10px", marginTop: "20px" }}
                                    className="font-14"
                                >
                                    {drapryAtomState.size}
                                </p>
                            </div>
                        </div>
                        <div className="sizes-wrapper sizes-desktop d-none d-lg-block">
                            <div className="d-flex align-items-center flex-wrap" id="myDIV">
                                {(Object.entries(draperyData)).map(([key, value]) => {

                                    return (
                                        <div className={`swatch-box me-3 position-relative mb-3 ${drapryAtomState.size === value.size && 'active'}`}
                                            key={key}
                                            onClick={
                                                () => {
                                                    setDrapryAtomState({
                                                        ...drapryAtomState,
                                                        size: key,
                                                        sizeImage: value.selectedImg
                                                    })
                                                }
                                            }>
                                            <img
                                                src={drapryAtomState.size === key ? value.selectedImg : value.unselectedImg}
                                                alt=""
                                                className="size-26"
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            case "TAB_2":
                return (
                    <div className="p-4" style={{ width: 500 }}>
                        <div className="border-bottom mb-4 w-100">
                            <p className="text-color font-17">BAND</p>
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-4 ">
                            {['All', 'Top', 'Bottom', 'Center'].map((item, index) => (
                                <div
                                    className={`side-box me-3 ${activeBand === item ? 'active' : ''} position-relative mb-3`}
                                    onClick={() => setActiveBand(item)}
                                    key={index}
                                >
                                    <img src={`${index + 1}_${activeBand === item ? 'black' : 'grey'}.png`} alt="" className="side-img" />
                                    <p className="font-14 text-black text-center mb-0">{item}</p>
                                </div>
                            ))}
                        </div>
                        <div className="control-wrapper">
                            <div className="d-flex align-items-center ">
                                <div className="form-group position-relative me-3 mw-100 w-100">
                                    <input
                                        type="type"
                                        placeholder="Search Paints"
                                        className="search-control mw-88"
                                    />
                                    <img src="search.png" className="search-icon" />
                                </div>
                            </div>
                            <div className="item-choose w-100 pt-3">
                                <div className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 w-100">
                                    <div className="item-choose me-3 mb-3">
                                        <img src="grey.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="creemy.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="purple.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="sky.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="blue.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="brown.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="purple-1.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="yellow.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Yellow
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="grey.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="creemy.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="purple.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="sky.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="blue.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="brown.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "TAB_3":
                return (
                    <div className="p-4" style={{ width: 500 }}>
                        <div className="border-bottom mb-4 w-90">
                            <p className="text-color font-17">TRIM ON THE BOTTOM</p>
                        </div>
                        <div className="control-wrapper">
                            <div className="d-flex align-items-center ">
                                <div className="form-group position-relative me-3 mw-88 w-100">
                                    <input
                                        type="type"
                                        placeholder="Search Paints"
                                        className="search-control"
                                        style={{ minWidth: "96%" }}
                                    />
                                    <img src="search.png" className="search-icon" />
                                </div>
                            </div>
                            <div className="item-choose w-100 pt-3">
                                <div
                                    className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 w-100"
                                >
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="border-1.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-2.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-3.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Yellow <br /> Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-4.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-5.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-6.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-7.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-8.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Yellow
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="border-1.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-2.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-3.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Yellow <br /> Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-4.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-5.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="border-6.png" className="img-fluid" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "TAB_4":
                return (
                    <div className="p-4" style={{ width: 500 }}>
                        <div className="border-bottom mb-4 w-90">
                            <p className="text-color font-17">NAILHEAD</p>
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-4 ">
                            {["All", "Top", "Bottom"].map((item, index) => (
                                <div
                                    className={`side-box me-3 ${activeNainhead === item ? 'active' : ''} position-relative mb-3`}
                                    key={item}
                                    onClick={() => setActiveNainhead(item)}>
                                    <img
                                        src={`${index + 1}_${activeNainhead === item ? 'black' : 'grey'}.png`}
                                        alt=""
                                        className="side-img"
                                    />
                                    <p className="font-14  text-center mb-0 text-gray">{item}</p>
                                </div>))}
                        </div>
                        <div className="control-wrapper">
                            <div className="d-flex align-items-center ">
                                <div className="form-group position-relative me-3 mw-100 w-100">
                                    <input
                                        type="type"
                                        placeholder="Search Paints"
                                        className="search-control mw-88"
                                    />
                                    <img src="search.png" className="search-icon" />
                                </div>
                            </div>
                            <div className="item-choose w-100 pt-3">
                                <div
                                    className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 w-100"
                                >
                                    <div className="item-choose me-3 mb-3">
                                        <img src="grey.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="creemy.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="purple.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="sky.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="blue.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="brown.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="purple-1.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="yellow.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Yellow
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="grey.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="creemy.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="purple.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="sky.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="blue.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="brown.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "TAB_5":
                return (
                    <div className="p-4" style={{ width: 500 }}>
                        <div className="border-bottom mb-4 w-90">
                            <p className="text-color font-17">WELT</p>
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-4 ">
                            {["All", "Top", "Bottom"].map((item, index) => (
                                <div
                                    className={`side-box me-3 ${activeWelt === item ? 'active' : ''} position-relative mb-3`}
                                    key={item}
                                    onClick={() => setActiveWeld(item)}>
                                    <img
                                        src={`${index + 1}_${activeWelt === item ? 'black' : 'grey'}.png`}
                                        alt=""
                                        className="side-img"
                                    />
                                    <p className="font-14  text-center mb-0 text-gray">{item}</p>
                                </div>))}
                        </div>
                        <div className="control-wrapper">
                            <div className="d-flex align-items-center ">
                                <div className="form-group position-relative me-3 mw-100 w-100">
                                    <input
                                        type="type"
                                        placeholder="Search Paints"
                                        className="search-control mw-88"
                                    />
                                    <img src="search.png" className="search-icon" />
                                </div>
                            </div>
                            <div className="item-choose w-100 pt-3">
                                <div
                                    className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 w-100"
                                >
                                    <div className="item-choose me-3 mb-3">
                                        <img src="grey.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="creemy.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="purple.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="sky.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="blue.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="brown.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="purple-1.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="yellow.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Yellow
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="grey.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Grey
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="creemy.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Creemy
                                        </p>
                                    </div>
                                    <div className="item-choose active me-3 mb-3">
                                        <img src="purple.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Purple
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="sky.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Sky
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="blue.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Blue
                                        </p>
                                    </div>
                                    <div className="item-choose me-3 mb-3">
                                        <img src="brown.png" alt="" className="side-img" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            Brown
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    }
    const noob = () => {
        return <div>
            <p>Something went wrong</p>
        </div>;
    }
    const loadCategoryCustomization = () => {
        switch (category) {
            case "pillow":
                return pillowTabData();
            case "vintage":
                return vintageTabData();
            case "drapery":
                return draperyTabData();
            case "upholstery":
                return UpholsteryTabData(
                    tab,
                    upholsteryAtomState,
                    setFabricImg,
                    upholsteryItem,
                    setUpholsteryItem,
                    upholsteryStatus,
                    setUpholsteryStatus,
                    modelAtomValue,
                    setModelAtomValue
                );
            default:
                return noob();
        }
    };

    const loadCategoryCustomizationTab = () => {
        switch (category) {
            case "pillow":
                return (
                    <ul>
                        <li
                            className={tab === "TAB_1" ? "active" : ""}
                            onClick={() => {
                                setTab("TAB_1")
                                setShowModal(false)
                                // setUploadImage({ })
                                // setFabricsAtomState({ ...fabricAtomState, currentPart: ['Flange', 'Flange_2_inch'] })
                                setFabricsAtomState({ ...fabricAtomState, currentPart: ["Front", "Back"] })
                            }}
                        >
                            <img
                                src="size.png"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">Size</p>
                        </li>
                        <li
                            className={tab === "TAB_2" ? "active" : ""}
                            onClick={() => {
                                setTab("TAB_2")
                                closeHandler()
                                setFabricsAtomState({ ...fabricAtomState, currentPart: [pillowSide] })
                            }}
                        >
                            <img
                                src="fabric.png"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">Fabric</p>
                        </li>
                        <li
                            className={tab === "TAB_3" ? "active" : ""}
                            onClick={() => {
                                setTab("TAB_3")
                                // setUploadImage({ })
                                setShowModal(false)
                                const edgeName = {
                                    '4 Side': 'Fringe',
                                    '2 Side': 'Fringe',
                                    'Flange 1"': 'Flange',
                                    'Flange 2"': 'Flange',
                                    'No': 'No'
                                }

                                // setFabricsAtomState({ ...fabricAtomState, currentPart: ['Flange', 'Flange_2_inch', "Welt", 'Fringe'] })
                                setFabricsAtomState({ ...fabricAtomState, currentPart: [edgeName[currentCustomization?.border?.name]] })
                            }}
                        >
                            <img
                                src="border.png"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">Edge</p>
                        </li>
                        {/* <li
                            className={tab === "TAB_4" ? "active" : ""}
                            onClick={() => setTab("TAB_4")}
                        >
                            <img
                                src="trim.png"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">Trim</p>
                        </li> */}
                        {/* ${["4 Side", "2 Side"].includes(currentCustomization.border.name) ? 'disabled' : ''} */}
                        <li
                            className={tab === "TAB_5" ? "active" : ""}
                            onClick={() => {
                                setShowModal(false)
                                // setUploadImage({ })
                                // if (["4 Side", "2 Side"].includes(currentCustomization.border.name)) return false;
                                setTab("TAB_5");
                                // setFabricsAtomState({ ...fabricAtomState, currentPart: [] })
                                // setFabricsAtomState({ ...fabricAtomState })
                                const singleBandName = {
                                    'Inset Band': ['Front_Inset_Band'],
                                    'Center': ['Front_Vertical_Band', 'Front_CenterBand'],
                                    'Vertical': ['Front_Double_Band'],
                                    'No': ['No']
                                }
                                const doubleBandName = {
                                    'Inset Band': ['Front_Inset_Band', 'Back_Inset_Band'],
                                    'Center': ['Front_Vertical_Band', 'Front_CenterBand', 'Back_CenterBand', 'Back_Vertical_Band'],
                                    'Vertical': ['Front_Double_Band', 'Back_Double_Band'],
                                    'No': ['No']
                                }
                                if (currentCustomization.band.type === 'double') {
                                    setFabricsAtomState({ ...fabricAtomState, currentPart: doubleBandName[currentCustomization?.band?.name] })
                                } else {
                                    setFabricsAtomState({ ...fabricAtomState, currentPart: singleBandName[currentCustomization?.band?.name] })
                                }
                            }}
                        >
                            <img
                                src="inset band.png"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2 text-center">BAND</p>
                        </li>
                        <li
                            className={tab === "TAB_6" ? "active" : ""}
                            onClick={() => {
                                setShowModal(false)
                                setTab("TAB_6");
                                // setFabricsAtomState({ ...fabricAtomState, currentPart: bandName[currentCustomization?.band?.name] })
                            }}
                        >
                            <img
                                src="Insert Type.png"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-0 text-center">INSERT</p>
                        </li>

                    </ul>
                );
            case "vintage":
                return (
                    <ul>
                        <li
                            className={tab === "TAB_1" ? "active" : ""}
                            onClick={() => setTab("TAB_1")}
                        >
                            <img
                                src={`Paint selected.svg`}
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">PAINT</p>
                        </li>
                        <li
                            className={tab === "TAB_2" ? "active" : ""}
                            onClick={() => {
                                setTab("TAB_2")
                            }}
                        >
                            <img
                                src={`Hardware selected.svg`}
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">HARDWARE</p>
                        </li>
                    </ul >);
            case "drapery":
                return (
                    <ul>
                        <li
                            className={tab === "TAB_1" ? "active" : ""}
                            onClick={() => setTab("TAB_1")}
                        >
                            <img
                                src="Drapery/Size.svg"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">Size</p>
                        </li>
                        <li
                            className={tab === "TAB_2" ? "active" : ""}
                            onClick={() => setTab("TAB_2")}
                        >
                            <img
                                src="Drapery/Size.svg"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">HEADER</p>
                        </li>
                        <li
                            className={tab === "TAB_3" ? "active" : ""}
                            onClick={() => setTab("TAB_3")}
                        >
                            <img
                                src="Drapery/HARDWARE.svg"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">HARDWARE</p>
                        </li>

                        <li
                            className={tab === "TAB_4" ? "active" : ""}
                            onClick={() => setTab("TAB_4")}
                        >
                            <img
                                src="Drapery/TRIM.svg"
                                className="img-fluid"
                                alt="product-choose"
                            />
                            <p className="tab-text mb-0 mt-2">TRIM</p>
                        </li>
                    </ul>
                );
            case "upholstery":
                return (
                    <ul>
                        {upholsterySidebar?.map(data => {
                            return <li
                                className={tab === data?.key ? "active" : ""}
                                onClick={() => {
                                    setTab(data?.key)
                                    setUpholsteryAtomState({ ...upholsteryAtomState, currentPart: data?.parts })
                                }}
                                key={data?.name}
                            >
                                <img
                                    src={data?.img}
                                    className="img-fluid"
                                    alt="product-choose"
                                />
                                <p className="tab-text mb-0 mt-2" style={{ textAlign: 'center' }}>{data?.name}</p>
                            </li>
                        })}

                    </ul>
                )
            default:
                return <div>
                    <p>Something went wrong</p>
                </div>;
        }
    }


    return (
        <>
            <div className="product-title text-center w-100">DESIGNER Customization</div>
            <div className="product-info-row container">
                {/* if image upload then show crop option otherwise show normal options */}
                {showModal ?
                    <CropImage />
                    : showBandModal ? <BandCropImage /> : <div className="row ">
                        <div className="col-lg-6 col-xl-6 left-part mt-rem-6 mt-0 d-flex justify-content-between" style={{
                            flexDirection: 'column',
                        }}>
                            {modelAtomValue.src ? category === 'pillow' ? <PillowModelViewer /> :
                                category === 'upholstery' ? <UpholsteryModelViewer showButton={true} /> :
                                    <GoogleModelViewer /> : ''}
                        </div>
                        <div className="col-lg-6 col-xl-6 right-part text-left mobile-shadow">
                            <div className="d-flex align-items-stretch tab-item-wrapper">
                                {loadCategoryCustomizationTab()}
                                <div className="item-infos me-4 w-100">
                                    <div className="tab-content">{
                                        loadCategoryCustomization()
                                    }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Customization;