/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
// import "@google/model-viewer";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  loaderAtom,
  modelAtom,
  preFixAtom,
  selectedAlphaAtom,
  vintageAtom,
} from "../../state";
import { GET_URL } from "../../constans";
import { normalAlpha } from "../../content";
import AntdSpin from "../Loader/AntdSpin";
const textures = {};

const GoogleModelViewer = ({ selectedProduct }) => {
  const paint = useRecoilValue(vintageAtom);
  const alphaSelect = useRecoilValue(selectedAlphaAtom)
  const [modelViewer, setmodelViewer] = useState(document.querySelector("#model-viewer"));
  const preFix = useRecoilValue(preFixAtom);
  const [loader, setLoader] = useRecoilState(loaderAtom)
  const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
  const getCheckSupport = localStorage.getItem('checkSupport')

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.querySelector("#model-viewer")) {
        setmodelViewer(document.querySelector("#model-viewer"))
      }
    }, 200);
    // Cancel the interval after 5 seconds (5000 milliseconds)
    setTimeout(() => {
      clearInterval(intervalId);
    }, 200);
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.getElementsByClassName('side-box active me-3 position-relative')?.[0]) {
        document.getElementsByClassName('side-box active me-3 position-relative')?.[0].click()
      }
    }, 200);
    // Cancel the interval after 5 seconds (5000 milliseconds)
    setTimeout(() => {
      clearInterval(intervalId);
    }, 200);
  }, [])

  useEffect(() => {
    if (modelViewer) {
      modelViewer.exposure = alphaSelect?.exposure
      updateMaterial();
      setTimeout(async () => {
        setLoader(false)
        // setModelAtomValue({
        //   ...modelAtomValue,
        //   loading: 100,
        // });
      }, 3000)
    }
  }, [paint, modelAtomValue.src, alphaSelect, modelViewer]);

  const createAndApplyTexture = async (channel, modelPartName, fabricImg, checkParams) => {
    const base64Image = (fabricImg?.search('data:image/png;base64') !== -1 || fabricImg?.search('data:image/jpeg;base64') !== -1) ? true : false
    let imageConvert = fabricImg
    if (getCheckSupport === 'true' && window.webpSupport && !base64Image) {
      imageConvert = fabricImg?.replaceAll('.jpeg', '.webp')
    }
    try {
      if (modelViewer?.model) {
        let texture = null;
        if (textures[fabricImg]) {
          texture = textures[fabricImg];
        } else {
          texture = await modelViewer.createTexture(base64Image ? fabricImg : checkParams ? fabricImg : GET_URL(imageConvert));
          textures[fabricImg] = texture;
        }

        const model = modelViewer.model;
        const parts = model[Object.getOwnPropertySymbols(model)[1]];

        const meterialIndexies = [];
        const partWiseIndex = {};

        parts.forEach((part) => {
          const index = part.initialMaterialIdx
          partWiseIndex[index] = part.name;
          if (modelPartName === part.name) {
            // if (modelPartName === part.name || part.name === "Wood") {
            meterialIndexies.push(index);
          }

        });

        meterialIndexies.forEach(index => {
          const material = modelViewer.model.materials[index];
          const pbrMR = material.pbrMetallicRoughness;

          if (channel.includes("base") || channel.includes("metallic")) {
            pbrMR[channel].setTexture(texture);
          } else {
            material[channel].setTexture(texture);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateMaterial = async () => {
    // if (modelViewer) {
    //   modelViewer.exposure = inputValue?.Exposure
    // }
    let keys, material;
    if (!paint.partWiseFabric) {
      return
    }
    const keyName = Object.keys(paint.partWiseFabric)
    keys = !keyName?.length ? alphaSelect?.name ? paint.currentPart : keyName : keyName;
    material = paint;

    if (keys.length === 0) {
      return;
    }
    for (const key of keys) {
      setLoader(true)

      if (material.partWiseFabric[key]) {
        await createAndApplyTexture(
          "baseColorTexture",
          key,
          selectedProduct?.alphaImage || material.partWiseFabric[key].diffuse
        );
        await createAndApplyTexture(
          "metallicRoughnessTexture",
          key,
          material.partWiseFabric[key].metallicRoughness
        );

        await createAndApplyTexture(
          "normalTexture",
          key,
          // alphaSelect?.type === 'wax' && alphaSelect?.name !== 'Clear Wax' ? normalAlpha[paint.name][alphaSelect?.name] : material.partWiseFabric[key].normal,
          // alphaSelect?.type === 'wax' && alphaSelect?.name !== 'Clear Wax'
          material.partWiseFabric[key].normal,
          false
        );
      }
    }

  };

  // useEffect(() => {
  //   if (modelViewer) {
  //     modelViewer.exposure = inputValue?.Exposure
  //   }
  // }, [inputValue]);

  if (modelViewer) {
    modelViewer.addEventListener("load", (data) => {
      const activeCategory = document.getElementsByClassName('active-category')[0];
      const name = activeCategory.querySelector('.card-text').innerHTML;
      if (paint?.name === name?.slice(4)?.split(" ")?.join("_")) {
        updateMaterial();
      }
    });
  }

  document.querySelector("#model-viewer")?.addEventListener("progress", (event) => {
    if (event.detail.totalProgress === 1) {
      // setModelAtomValue({
      //   ...modelAtomValue,
      //   loading: 100,
      // })
      setLoader(false)
    }
  })


  if (modelAtomValue.src.replace('app', preFix) && preFix) {
    return (
      <>
        <div style={{ position: 'relative' }}>
          {/* {modelAtomValue.loading === 0 && <AntdSpin />} */}
          {loader && <AntdSpin />}
          <div id="card" style={{
            opacity: loader ? '0' : '1',
            // opacity: modelAtomValue.loading === 100 ? '1' : '0',
            transition: 'opacity 1s ease-in-out',
            height: "57vh", width: "100%"
          }}>
            <model-viewer
              tone-mapping="neutral"
              src={modelAtomValue.src.replace('app', preFix)}
              camera-controls
              disable-pan
              // camera-orbit="0deg 90deg 3m"
              style={{ height: "57vh", width: "100%" }}
              id="model-viewer"
            ></model-viewer>
          </div>
        </div>
      </>
    );
  } else {
    return <></>
  }
};

export default GoogleModelViewer;
